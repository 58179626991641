/**
 *
 * @group Components
 * @subgroup Stepper
 *
 * @description A stepper is an interface for users to show numbered steps or for navigation.
 *
 * @base .#{$class-prefix}-stepper Default stepper
 *
 * @example <div class="pb-stepper theme-header [modifierClass]">... TEMPLATE</div>
 *
 * @markup
 * <div class="pb-stepper theme-header [modifierClass]">
 *    <div class="pb-stepper_connector"></div>
 *    <div class="pb-stepper_step">
 *       <div class="pb-stepper_step-first"></div>
 *       <div class="pb-stepper_step-content">
 *          <a href="#" class="pb-stepper_step-circle">
 *             <svg viewBox="-4 -4 32 32" class="pb-icon">
 *                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-done"></use>
 *             </svg>
 *          </a>
 *          <label class="pb-stepper_step-label">Adresse</label>
 *       </div>
 *    </div>
 *    <div class="pb-stepper_step">
 *       <div class="pb-stepper_step-content">
 *          <a href="#" class="pb-stepper_step-circle">
 *             <svg viewBox="-4 -4 32 32" class="pb-icon">
 *                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-done"></use>
 *             </svg>
 *          </a>
 *          <label class="pb-stepper_step-label">Zahlungsart </label>
 *       </div>
 *    </div>
 *    <div class="pb-stepper_step pb-stepper_step--active">
 *       <div class="pb-stepper_step-last"></div>
 *       <div class="pb-stepper_step-content">
 *          <a href="#" class="pb-stepper_step-circle">3</a>
 *          <label class="pb-stepper_step-label">Übersicht</label>
 *       </div>
 *    </div>
 * </div>
 *
 */

.#{$class-prefix}-stepper {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin: 0;
    padding: getGrid(4px) 0;
    min-height: getGrid(18px);
    height: auto;
    border-width: 0;
}

.#{$class-prefix}-stepper--largesteps {
    .#{$class-prefix}-stepper_step-circle {
        min-width: getGrid(12px);
        width: getGrid(12px);
        min-height: getGrid(12px);
        height: getGrid(12px);

        @include getTypography(title);

        line-height: getGrid(12px);

        .#{$class-prefix}-icon {
            min-width: getGrid(12px);
            min-height: getGrid(12px);
        }
    }

    .#{$class-prefix}-stepper_step-label {
        @include getTypography(subheading);
    }
}

/**
 *
 * @group Components
 * @subgroup Stepper
 *
 * @base .#{$class-prefix}-stepper_step Default step
 *
 * @modifier .#{$class-prefix}-stepper_step--active Active step
 * @modifier
 *
 * @example <div class="pb-stepper_step [modifierClass]">... TEMPLATE</div>
 *
 * @markup
 * <div class="pb-stepper_step [modifierClass]">
 *    <div class="pb-stepper_step-last"></div>
 *    <div class="pb-stepper_step-content">
 *       <a href="#" class="pb-stepper_step-circle">3</a>
 *       <label class="pb-stepper_step-label">Übersicht</label>
 *    </div>
 * </div>
 *
 */

.#{$class-prefix}-stepper_step {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 0;
    padding: 0;
    height: auto;
    text-align: center;
    line-height: 1;

    &:first-of-type::before,
    &:last-of-type::after {
        opacity: 0;
        max-width: 0;
    }

    &::before {
        content: '';
        flex: 1;
        height: 1px;
        background-color: getColor(dividers, dark);
    }

    &::after {
        content: '';
        height: 1px;
        background-color: getColor(dividers, dark);
        flex: 1;
    }
}

.#{$class-prefix}-stepper_step-circle {
    display: inline-block;
    margin: 0 getGrid(4px);
    padding: 0;
    width: getGrid(6px);
    height: getGrid(6px);
    min-width: getGrid(6px);
    min-height: getGrid(6px);
    border-radius: 50%;
    background-color: getColor(disabled);
    color: getColor(text, light);
    vertical-align: middle;
    text-align: center;

    @include getFontSize(body);
    @include getFontWeight(medium);

    line-height: getGrid(6px);

    .#{$class-prefix}-icon {
        min-width: getGrid(6px);
        width: getGrid(6px);
        height: getGrid(6px);
        min-height: getGrid(6px);
        padding: 0;
        fill: getColor(text, light);

        &:active {
            background-color: transparent;
        }
    }
}

.#{$class-prefix}-stepper_step--active {
    .#{$class-prefix}-stepper_step-circle {
        background-color: getColor(primary);
    }

    .#{$class-prefix}-stepper_step-circle:active {
        background-color: getColor(primary, dark);
    }

    .#{$class-prefix}-stepper_step-label {
        color: getColor(text);
    }
}

.#{$class-prefix}-stepper_step-label {
    position: relative;
    display: inline-block;
    margin-left: getGrid(-1px);
    padding: 0 getGrid(2px) 0 0;
    height: auto;
    color: getColor(disabled);
    vertical-align: middle;
    white-space: nowrap;

    @include getTypography(body-2);

    line-height: getGrid(4.5px);
}

@media screen and (min-width: 0) and (max-width: getBreakpoints(phone-s) - 1px) {
    .#{$class-prefix}-stepper {
        min-height: getGrid(14px);

        .#{$class-prefix}-stepper_step-label {
            display: none;
        }
    }
}

@media screen and (min-width: getBreakpoints(phone-s)) and (max-width: getBreakpoints(phone-l) - 1px) {
    .#{$class-prefix}-stepper {
        min-height: getGrid(21px);

        .#{$class-prefix}-stepper_step {
            display: block;

            &:first-of-type::before,
            &:last-of-type::after {
                opacity: 0;
                max-width: 0;
                flex: none;
            }

            &::before {
                z-index: 0;
                position: absolute;
                left: 0;
                top: getGrid(3px);
                width: calc(50% - 24px);
            }

            &::after {
                z-index: 0;
                position: absolute;
                right: 0;
                top: getGrid(3px);
                width: calc(50% - 24px);
            }
        }

        .#{$class-prefix}-stepper_step-label {
            display: block;
            margin: 0;
            padding: getGrid(2px) 0 0 0;
            white-space: normal;
            text-align: center;
        }
    }
}
