/**
 *
 * @group Components
 * @subgroup Toolbar
 *
 * @description Toolbars appear a step above the sheet of paper affected by their actions. When sheets scroll underneath toolbars, they are clipped and cannot pass through to the opposite side.
 *
 * @base .#{$class-prefix}-toolbar Default toolbar
 *
 * @example <div class="pb-toolbar">... TEMPLATE</div>
 *
 * @markup
 * <nav class="pb-toolbar pb-theme_primary">
 *    <svg class="pb-icon" viewBox="-4 -4 32 32">
 *        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-menu"></use>
 *    </svg>
 *    <h1 class="pb-appbar_title">App bar</h1>
 *    <svg class="pb-icon" viewBox="-4 -4 32 32">
 *        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-search"></use>
 *    </svg>
 *    <svg class="pb-icon" viewBox="-4 -4 32 32">
 *        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-more_vert"></use>
 *    </svg>
 * </nav>
 *
 */

.#{$class-prefix}-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    margin: 0;
    padding: getGrid(1px);
    width: auto;
    height: getGrid(14px);
    background-color: inherit;
    white-space: nowrap;
}

.#{$class-prefix}-appbar_title {
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0 getGrid(3px) 0 getGrid(3px);
    text-overflow: ellipsis;

    @include getTypography(title);

    line-height: getGrid(12px);
    opacity: 1;
    transition: opacity 0.3s getAnimation(ease);
    flex: 1;
    text-rendering: optimizeLegibility;
}

.#{$class-prefix}-icon + .#{$class-prefix}-appbar_title {
    padding-left: getGrid(5px);
}

.#{$class-prefix}-toolbar_title {
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;

    @include getTypography(subheading-2);

    line-height: 49px;
    opacity: 1;
    transition: opacity 0.3s getAnimation(ease);
    flex: 1;
    text-rendering: optimizeLegibility;
}
