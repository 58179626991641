/**
 *
 * @group Components
 * @subgroup Dividers
 *
 * @description Dividers group and separate content within lists and page layouts. The divider is a thin rule, lightweight yet sufficient to distinguish content visually and spatially.
 *
 * @base .#{$class-prefix}-divider Full bleed Dividers
 *
 * @modifier .#{$class-prefix}-divider--indent Inset Dividers | Inset dividers separate related content, such as sections in a list of contacts or emails in a conversation.
 * @modifier
 *
 * @example <hr class="pb-divider [modifierClass]"> />
 *
 * @markup
 * <div class="pb-divider"></div>
 *
 */

.#{$class-prefix}-divider {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid getColor(dividers);

    &:last-child {
        border-bottom: none;
    }
}

.#{$class-prefix}-divider--indent {
    margin-left: getGrid(18px);
}

.#{$class-prefix}-divider-top {
    border-top: 1px solid getColor(dividers);
}
