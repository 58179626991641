/**
 *
 * @group Components
 * @subgroup Expendable
 *
 * @description Expendable are used to give more information on demand.
 *
 * @base .#{$class-prefix}-expandable Expendable
 *
 * @modifier .#{$class-prefix}-expandable--animate Expendable animation | Animation to open the expandable
 * @modifier
 *
 * @example <div class="pb-expandable">... HIDDEN TEMPLATE</div>
 *
 * @markup
 * <div class="pb-list_item">
 *    <div class="pb-list_header">Show details</div>
 *    <button class="pb-is-link">
 *        <svg class="pb-icon pb-icon--expand" viewBox="-4 -4 32 32">
 *            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-keyboard_arrow_down"></use>
 *        </svg>
 *    </button>
 * </div>
 *
 */

.#{$class-prefix}-expandable {
    display: block;
    overflow: hidden;
    height: auto;
    max-height: 0;
    transition: max-height 0.25s cubic-bezier(0, 0, 0, 1);
    will-change: max-height;
}

.#{$class-prefix}-expandable--animate {
    //  display: block;
    max-height: 200vh !important;
    transition: max-height 0.25s cubic-bezier(0, 0, 1, 0) !important;
}
