/**
 *
 * @group Components
 * @subgroup Input and controls
 *
 * @description Radio buttons allow the user to select one option from a set. Use radio buttons for exclusive selection if you think that the user needs to see all available options side-by-side. Otherwise, consider a dropdown, which uses less space than displaying all options.
 *
 * @base .#{$class-prefix}-control Control buttons - Radio & Checkbox
 *
 * @modifier .#{$class-prefix}-control--simple Simple radio button
 * @modifier
 *
 * @state :checked Active checked radio
 * @state
 *
 * @markup
 * <div class="pb-control [modifierClass]">
 *    <input type="radio" id="gender_contactForm_male">
 *    <label for="gender_contactForm_male">
 *        <svg viewBox="-4 -4 32 32" class="pb-icon pb-color-text_primary">
 *            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-radio_button_unchecked"></use>
 *        </svg>
 *        <svg viewBox="-4 -4 32 32" class="pb-icon pb-color-text_primary a-zoomcontrol">
 *            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-radio_button_checked"></use>
 *        </svg>
 *    </label>
 * </div>
 */

$controls-color: getEncodedColor(getColor(secondary));
$controls-error: getEncodedColor(getColor(error));

.#{$class-prefix}-control {
  position: relative;
  width: getGrid(12px);
  height: getGrid(12px);
  border-radius: 50%;
  line-height: 1;

  input[type='checkbox'],
  input[type='radio'] {
    display: none;
  }

  input + label {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: background-color 1.8s getAnimation(ease);

    &:active {
      background-color: getColor(dividers);
      transition: background-color 0.6s getAnimation(ease);
    }

    .#{$class-prefix}-icon {
      fill: getColor(secondary);
    }

    .#{$class-prefix}-icon:first-of-type {
      display: block;
    }

    .#{$class-prefix}-icon:last-of-type {
      display: none;
    }
  }

  input:checked + label {
    .#{$class-prefix}-icon:first-of-type {
      display: none;
    }

    .#{$class-prefix}-icon:last-of-type {
      display: block;
    }
  }
}

.#{$class-prefix}-control--simple {
  input + label {
    .#{$class-prefix}-icon:first-of-type {
      display: none;
    }
  }
}

// zoom in animation class
.a-zoomcontrol {
  animation: animation-zoomcontrol 1.2s getAnimation(ease);
}

// zoom in animation
@keyframes animation-zoomcontrol {
  0% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1);
  }
}

.#{$class-prefix}-control_label {
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding: 13px getGrid(4px) 0 getGrid(2px);
  height: auto;
  color: getColor(secondarytext);
  text-align: left;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  flex: 1;
}

.#{$class-prefix}-control--error input + label .#{$class-prefix}-icon {
  fill: getColor(error) !important;
}

.#{$class-prefix}-hint.#{$class-prefix}-hint--control {
  transform: initial;
  margin: 0 getGrid(2px);
}

/**
 *
 * @group Components
 * @subgroup Input and controls
 *
 * @description Expendable are used to give more information on demand.
 *
 * @base .#{$class-prefix}-switch Switches
 *
 * @modifier .#{$class-prefix}-switch--inline Inline switch
 * @modifier .#{$class-prefix}-switch--alignright Align right
 *
 * @markup
 * <div class="pb-switch [modifierClass]">
 *    <input type="checkbox" class="pb-switch_input">
 *    <label for="dispatch_packstation" class="pb-switch_inset"></label>
 * </div>
 *
 */

.#{$class-prefix}-switch {
  display: inline-flex;
  margin: 0;
  padding: 0;
  width: getGrid(12px);
  height: getGrid(12px);
  text-align: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 1.8s getAnimation(ease);
  background-color: transparent;

  &:active {
    background-color: getColor(dividers);
    transition: background-color 0.6s getAnimation(ease);
  }
}

.#{$class-prefix}-switch_inset {
  position: relative;
  display: inline-block;
  overflow: initial;
  align-self: center;
  margin: 0;
  padding: 0;
  width: getGrid(8px);
  height: getGrid(3px);
  border-radius: 100px;
  background-color: getColor(disabled);
  cursor: pointer;
  transition: all 0.3s ease;

  &::after {
    position: absolute;
    top: -5px;
    left: -2px;
    transform: translate(0, 0);
    display: block;
    width: getGrid(5px);
    height: getGrid(5px);
    border-radius: 50%;
    background-color: getColor(grey, 200);

    @extend .#{$class-prefix}-raised_2;

    content: '';
    transition: all 0.3s ease;
  }
}

.#{$class-prefix}-switch_label {
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding: getGrid(3px) getGrid(4px) 0 getGrid(5px);
  height: auto;
  //  color: getColor(secondarytext);

  @include getTypography(body);

  text-align: left;
  text-overflow: ellipsis;
  flex: 1;
}

.#{$class-prefix}-switch_input {
  display: none;

  &:checked ~ .#{$class-prefix}-switch_inset {
    background-color: rgba(getColor(secondary), 0.5);
  }

  &:checked ~ .#{$class-prefix}-switch_inset::after {
    // left: getGrid(4px);
    transform: translate(#{getGrid(4px)}, 0);
    background-color: getColor(secondary);

    @extend .#{$class-prefix}-raised_3;
  }

  &:disabled ~ .#{$class-prefix}-switch_inset {
    background-color: getColor(disabled);
    pointer-events: none;
  }

  &:disabled ~ .#{$class-prefix}-switch_inset::after {
    background-color: nth($md-list-grey, 3);
  }
}

.#{$class-prefix}-switch--inline {
  width: getGrid(12px);
  height: auto;
  display: inline-flex !important;
  align-self: center;

  .#{$class-prefix}-switch {
    justify-content: center;
    height: auto;

    &:active {
      background-color: transparent;
    }
  }

  .#{$class-prefix}-switch_label {
    padding: 0 getGrid(1px);
  }
}

.#{$class-prefix}-switch--alignright {
  width: 100%;
  padding-right: getGrid(1px);

  .#{$class-prefix}-switch_label {
    padding-left: getGrid(4px);
  }
}
