/**
 *
 * @group Components
 * @subgroup Input and controls
 *
 * @description Text fields allow the user to input text, select text, and lookup data via auto-completion.
 *
 * @base .#{$class-prefix}-textarea Text area
 *
 * @modifier .#{$class-prefix}-textarea--active Active textarea
 * @modifier
 *
 * @markup
 * <div class="pb-textarea [modifierClass]">
 *    <textarea id="question"></textarea>
 *    <label for="question">Frage</label>
 * </div>
 *
 */

.#{$class-prefix}-textarea {
  position: relative;
  margin: 0 0 getGrid(2px) 0;
  height: getGrid(26px);
  flex: 1;

  .#{$class-prefix}-textarea_input,
  textarea {
    position: absolute;
    bottom: 1px;
    left: 0;
    display: block;
    overflow: auto;
    margin: 0 0 getGrid(6px) 0;
    padding: getGrid(1px);
    width: 100%;
    height: getGrid(14px);
    border: none;
    border-bottom: 1px solid getColor(disabled);

    @include getTypography(body-1);

    border-radius: 0;
    color: getColor(text);

    @include getTransition();

    line-height: 1;

    // provide simple animation if guide is not present
    &:focus {
      animation: #{$class-prefix}-textfield-input-animation 0.3s forwards;
      animation-delay: 0.15s;
    }
  }

  .#{$class-prefix}-hint {
    transform: translate(0, getGrid(17px));
  }

  label {
    margin: 0;
    padding: 0 getGrid(1px);
    height: getGrid(5px);
    color: getColor(secondarytext);

    @include getFontWeight(normal);
    @include getFontSize(subheading);

    line-height: 1;
    opacity: 1;
    cursor: text;
    transform: translate(0, 24px) scale(1);
    transform-origin: left;

    @include getTransition(0.6s);

    pointer-events: none;
  }

  .#{$class-prefix}-textarea_guide {
    position: absolute;
    bottom: 18px;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: getColor(primary, contrast);
    pointer-events: none;
    transform: scaleX(0);
    transform-origin: bottom center;
    transition: transform 0.6s getAnimation(ease);
  }

  .#{$class-prefix}-textarea_input:focus + .#{$class-prefix}-textarea_guide,
  textarea:focus + .#{$class-prefix}-textarea_guide {
    transform: scaleX(1);
  }
}

.#{$class-prefix}-textarea--active label {
  color: getColor(primary, contrast);

  @include getFontWeight(normal);

  opacity: 1;
  transform: translate(0, getGrid(2px)) scale(0.75);
}

/*
.#{$class-prefix}-textarea textarea:focus ~ label,
.#{$class-prefix}-textarea textarea:valid ~ label {
  @include getFontWeight(normal);

  opacity: 1;
  transform: translate(0, getGrid(2px)) scale(0.75);
}
*/

/**
 *
 * @group Components
 * @subgroup Input and controls
 *
 * @description Text fields allow the user to input text, select text, and lookup data via auto-completion.
 *
 * @base .#{$class-prefix}-textfield Text field
 *
 * @modifier .#{$class-prefix}-textfield--dense Dense text field | Shorter height
 * @modifier .#{$class-prefix}-textfield--active On active
 * @modifier .#{$class-prefix}-textfield--focus On focus
 * @modifier .#{$class-prefix}-textfield--password With password
 *
 * @markup
 * <div class="pb-textfield [modifierClass]">
 *    <input type="text" name="orderNumber" id="orderNumber">
 *    <label for="orderNumber">[description]</label>
 * </div>
 *
 */

.#{$class-prefix}-textfield {
  position: relative;
  margin: 0 0 getGrid(2px) 0;
  height: getGrid(19px);
  flex: 1;
  overflow: hidden;

  input,
  textarea,
  .#{$class-prefix}-textfield_input {
    position: absolute;
    bottom: 1px;
    left: 0;
    display: block;
    margin: 0 0 0 0;
    margin-bottom: getGrid(7px);
    padding: 0 getGrid(1px);
    width: 100%;
    height: getGrid(7px);
    border: none;
    border-bottom: 1px solid getColor(disabled);
    -webkit-appearance: none;

    @include getTypography(body);
    @include getFontSize(subheading);

    line-height: 1;
    border-radius: 0;
    color: getColor(text);

    @include getTransition();

    // provide simple animation if guide is not present
    &:focus {
      animation: #{$class-prefix}-textfield-input-animation 0.3s forwards;
      animation-delay: 0.15s;
    }
  }

  @keyframes #{$class-prefix}-textfield-input-animation {
    100% {
      border-bottom: 2px solid getColor(primary, contrast);
    }
  }

  .#{$class-prefix}-textfield_guide {
    position: absolute;
    bottom: 21px;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: getColor(primary, contrast);
    pointer-events: none;
    transform: scaleX(0);
    transform-origin: bottom center;
    transition: transform 0.6s getAnimation(ease);
  }

  .#{$class-prefix}-textfield_input:focus + .#{$class-prefix}-textfield_guide,
  input:focus + .#{$class-prefix}-textfield_guide {
    transform: scaleX(1);
  }

  label,
  .#{$class-prefix}-textfield_label {
    margin: 0;
    padding: 0 getGrid(1px);
    height: getGrid(5px);
    color: getColor(secondarytext);

    @include getFontWeight(normal);
    @include getFontSize(subheading);

    line-height: 1;
    opacity: 1;
    cursor: text;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translate(0, getGrid(6px)) scale(1);
    transform-origin: left;

    @include getTransition(0.6s);

    pointer-events: none;
  }
}

.#{$class-prefix}-textfield.#{$class-prefix}-textfield--contrast {
  .#{$class-prefix}-textfield_input,
  input {
    background-color: getAlpha(200);
    border-radius: getRadius(radius, small);
    border-bottom: none;
    animation: none;

    &:focus {
      border-bottom: none;
    }
  }
}

.#{$class-prefix}-textfield--active {
  label,
  .#{$class-prefix}-textfield_label {
    @include getFontWeight(normal);

    opacity: 1;
    transform: translate(0, getGrid(1px)) scale(0.75);
  }
}

/*
.#{$class-prefix}-textfield input:focus ~ label,
.#{$class-prefix}-textfield input:valid ~ label {
  @include getFontWeight(normal);

  opacity: 1;
  transform: translate(0, getGrid(1px)) scale(0.75);
}
*/

.#{$class-prefix}-textfield--focus::after {
  width: 100%;
}

.#{$class-prefix}-textfield--dense {
  height: getGrid(17px);

  input {
    margin-bottom: getGrid(5px);
  }

  .#{$class-prefix}-hint {
    transform: translate(0, getGrid(8.5px));
  }

  &::after {
    bottom: 21px;
  }
}

.#{$class-prefix}-hint {
  padding: 0 getGrid(1px);
  color: getColor(secondarytext);

  @include getTypography(caption);

  transform: translate(0, getGrid(8px));
}

.#{$class-prefix}-textfield--password {
  input {
    padding-right: getGrid(11px);
  }

  input[type='password'] {
    letter-spacing: 4px;
  }

  .#{$class-prefix}-icon {
    position: absolute;
    bottom: getGrid(5.5px);
    right: getGrid(-1px);
    fill: getColor(disabled);
    transition: transform 0.3s getAnimation(ease);
    transform: scale(1);
  }

  .#{$class-prefix}-icon--visible {
    fill: getColor(secondarytext);
    transform: scale(1.25);
  }
}

.#{$class-prefix}-textfield.#{$class-prefix}-textfield--simple {
  height: getGrid(12px);
  margin: 0;

  .#{$class-prefix}textfield_input,
  input {
    margin-bottom: 10px;
    bottom: 0;
  }

  .#{$class-prefix}-textfield_guide {
    bottom: 2px;
  }
}

.#{$class-prefix}-icon--searchreset {
  opacity: 0 !important;
  min-width: 0;
  width: 0;
}