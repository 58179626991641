/**
 *
 * @group Components
 * @subgroup Toast & Snackbars
 *
 * @description Snackbars provide lightweight feedback about an operation by showing a brief message at the bottom of the screen. Snackbars can contain an action.
 *
 * @base .#{$class-prefix}-snackbar Snackbar
 *
 * @modifier .#{$class-prefix}-snackbar--animate Snackbar animation | Animation to show/hide the Snackbar
 * @modifier
 *
 * @example <div class="pb-snackbar [modifierClass]">... TEMPLATE-CODE</div>
 *
 * @markup
 * <div class="pb-snackbar [modifierClass] pb-theme_dark styleguide-helper pb-margin_2">
 *    <div class="pb-list_item">
 *        <div class="pb-list_multiline ">
 *            <div class="pb-list_header2">A snackbar with action area</div>
 *        </div>
 *        <button class="pb-button pb-button--text">Action</button>
 *    </div>
 * </div>
 *
 */

.#{$class-prefix}-snackbar {
    position: fixed;
    bottom: 0;
    left: 50%;
    display: inline-flex;
    align-items: center;
    margin: 0 auto;
    padding: 0;
    min-width: getGrid(72px);
    min-height: getGrid(12px);
    max-width: getGrid(142px);
    background-color: getColor(background, dark);

    @include getTypography(body);

    color: getColor(text, light);

    @include getDepth(6);

    // hack to put snackbar above dialogfullscreen
    z-index: 9900 !important;
    text-align: left;
    transition: transform 0.9s getAnimation(ease);
    transform: translate(-50%, calc(100% + 8px));

    .#{$class-prefix}-icons-right {
        min-width: getGrid(6px);
        width: getGrid(6px);
        height: getGrid(6px);
        min-height: getGrid(6px);
        line-height: getGrid(6px);
    }
}

.#{$class-prefix}-snackbar [class^='pb-button'] .#{$class-prefix}-icon {
    fill: getColor(text, light) !important;
}

.#{$class-prefix}-snackbar--animate {
    transform: translate(-50%, 0);
}

// *** adaptive gui
@media screen and (min-width: getBreakpoints(phone-xs)) {
    .#{$class-prefix}-snackbar {
        width: 100%;
    }
}

@media screen and (min-width: getBreakpoints(phone-l)) {
    .#{$class-prefix}-snackbar {
        width: getGrid(142px);
    }
}
