/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Structure
 */

.#{$class-framework} {
    display: flex;
    position: relative;
    overflow: auto;
    margin: auto;
    padding: 0;
    background-color: getColor(background);
    color: getColor(text);
    font-family: getTypography(font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    scroll-behavior: smooth;
    overflow-anchor: none;
}

.#{$class-prefix}-paper {
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    height: auto;
}

// *** test for sticky header
.#{$class-prefix}-paper--headersticky {
    padding-top: getGrid(14px);

    > .#{$class-prefix}-header {
        position: fixed;
        top: 0;

        @include getDepth(4);
    }

    &.#{$class-prefix}-paper--headermedium {
        padding-top: getGrid(26px);
    }

    &.#{$class-prefix}-paper--mediumlarge {
        padding-top: getGrid(28px);
    }

    &.#{$class-prefix}-paper--headerlarge {
        padding-top: getGrid(39px);
    }
}

.#{$class-prefix}-paper--headermedium {
    .#{$class-prefix}-header {
        height: getGrid(26px);
    }
}

.#{$class-prefix}-paper--mediumlarge {
    .#{$class-prefix}-header {
        height: getGrid(28px);
    }
}

.#{$class-prefix}-paper--headerlarge {
    .#{$class-prefix}-header {
        height: getGrid(39px);
    }
}

.#{$class-prefix}-content {
    padding: getGrid(4px);
}

.#{$class-prefix}-content--hasheader {
    padding-top: 0;
}

.#{$class-prefix}-content--fullheight {
    min-height: calc(100vh - 56px);
}

// *** fluid layouts
// use .#{$class-prefix}-is-fluid to indicate fluid behaviour

//@media screen and (min-width: getBreakpoints(tablet-l-land)) {
//  .#{$class-prefix}-paperbase.#{$class-prefix}-is-fluid {
//    width: getBreakpoints(tablet-l-land) !important;
//    overflow-x: auto;
//    box-shadow: 0 1px 2px getColor(disabled);
//
//    .#{$class-prefix}-drawer.#{$class-prefix}-is-fluid,
//    .#{$class-prefix}-dialogfullscreen.#{$class-prefix}-is-fluid {
//      position: absolute;
//      width: getGrid(60px);
//      max-width: getGrid(60px);
//      transform: translate(0, 0);
//      box-shadow: 0 1px 2px getColor(disabled);
//    }
//
//    .#{$class-prefix}-dialogfullscreen.#{$class-prefix}-is-fluid {
//      z-index: 2199;
//
//      .#{$class-prefix}-toolbar {
//        .#{$class-prefix}-icon:first-of-type {
//          display: none;
//        }
//
//        .#{$class-prefix}-toolbar_title {
//          margin-left: getGrid(4px);
//        }
//      }
//    }
//
//    .#{$class-prefix}-header.#{$class-prefix}-is-fluid {
//      width: getGrid(196px);
//      left: getGrid(60px);
//    }
//
//    .#{$class-prefix}-paper.#{$class-prefix}-is-fluid {
//      width: getGrid(196px);
//      left: getGrid(60px);
//    }
//
//    .#{$class-prefix}-icon.#{$class-prefix}-is-fluid {
//      display: none;
//    }
//  }
//}
//
//@media screen and (min-width: getBreakpoints(tablet-xl-land)) {
//  .#{$class-prefix}-paperbase.#{$class-prefix}-is-fluid {
//    width: getBreakpoints(tablet-xl-land) !important;
//    overflow-x: auto;
//
//    .#{$class-prefix}-drawer.#{$class-prefix}-is-fluid,
//    .#{$class-prefix}-dialogfullscreen.#{$class-prefix}-is-fluid {
//      width: getGrid(80px);
//      max-width: getGrid(80px);
//      box-shadow: 0 1px 2px getColor(disabled);
//    }
//
//    .#{$class-prefix}-header.#{$class-prefix}-is-fluid {
//      width: getGrid(240px);
//      left: getGrid(80px);
//    }
//
//    .#{$class-prefix}-paper.#{$class-prefix}-is-fluid {
//      width: getGrid(240px);
//      left: getGrid(80px);
//    }
//
//    .#{$class-prefix}-icon.#{$class-prefix}-is-fluid {
//      display: none;
//    }
//  }
//}
