/**
 *
 * @group Components
 * @subgroup Input and controls
 *
 * @description Dropdowns allow users to take an action by selecting from a list of choices revealed upon opening a temporary, new sheet of material
 *
 * @base .#{$class-prefix}-dropdown Dropdown
 *
 * @modifier .#{$class-prefix}-dropdown--inline Inline dropdown
 * @modifier .#{$class-prefix}-dropdown--simple Simple dropdown
 * @modifier .#{$class-prefix}-dropdown--single Single dropdown
 *
 * @state :active Active dropdown
 * @state :focus Text field on focus
 *
 * @markup
 * <div class="pb-dropdown [modifierClass]">
 *    <label class="pb-color-text_error" for="questions">Betreff
 *        <span class="pb-required">*</span>
  *   </label>
 *    <svg viewBox="-4 -4 32 32" class="pb-icon pb-icon--selectbox">
 *        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-arrow_drop_down"></use>
 *    </svg>
 *    <select name="questions" class="pb-dropdown_select" tabindex="2">
 *        <option disabled="" value="-" class="pb-dropdown_first">Bitte wählen</option>
 *        <option value="Frage zu einem Produkt">Frage zu einem Produkt</option>
 *        <option value="Frage zu Zahlung oder Lieferung ">Frage zu Zahlung oder Lieferung</option>
 *    </select>
 * </div>
 *
 */

.#{$class-prefix}-dropdown {
    position: relative;
    display: inline-block;
    margin: 0 0 getGrid(2px) 0;
    height: getGrid(19px);
    background-color: inherit;
    //  min-width: getGrid(34px);
    width: 100%;
    flex: 1;
    overflow: hidden;

    .#{$class-prefix}-icon {
        z-index: 1;
        position: absolute;
        top: getGrid(5px);
        right: 0;
        margin: 0;
        min-width: getGrid(8px);
        width: getGrid(8px);
        min-height: getGrid(8px);
        height: getGrid(8px);
        padding: 0;
        border-radius: 0;
        background: none;
        fill: getColor(disabled);
        pointer-events: none;

        &:active {
            background: inherit;
        }
    }

    .#{$class-prefix}-dropdown_label,
    label {
        //background: getColor(content);
        position: relative;
        //top: getGrid(1px);
        left: 0;
        padding-left: getGrid(1px);
        padding-right: getGrid(6px);
        width: auto;
        height: getGrid(5px);
        z-index: 1;

        @include getTypography(body-1);
        @include getFontSize(caption);

        color: getColor(secondarytext);
        transition: transform 0.6s getAnimation(ease),
            background 0.3s getAnimation(ease);
        transform: translate(0, getGrid(7px)) scale(1.25);
        transform-origin: left;
        pointer-events: none;

        .#{$class-prefix}-required {
            font-size: 10px;
        }
    }

    .#{$class-prefix}-hint {
        position: absolute;
        top: getGrid(6.5px);
        left: getGrid(0.5px);
        width: 100%;
    }
}

.#{$class-prefix}-dropdown_select {
    position: absolute;
    bottom: getGrid(3px);
    left: 0;
    display: block;
    margin: 0 0 getGrid(3px) 0;
    padding: 0 getGrid(9px) 0 getGrid(1px);
    width: 100%;
    height: getGrid(7px);
    border: none;
    border-radius: 0;
    opacity: 0;

    @include getTypography(body);
    @include getFontSize(subheading);

    line-height: 1;
    color: getColor(disabled);
    -webkit-appearance: none;
    -moz-appearance: none;
}

.#{$class-prefix}-dropdown--active {
    .#{$class-prefix}-dropdown_label,
    label {
        background: none;
        transform: translate(0, getGrid(2px)) scale(1);
        font-weight: 400;
    }

    .#{$class-prefix}-dropdown_select {
        opacity: 1;
        color: getColor(text);
    }
}

.#{$class-prefix}-dropdown_guide {
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: getColor(dividers);
    pointer-events: none;
    transform: scaleX(1);
    transform-origin: bottom center;
    transition: transform 0.6s getAnimation(ease);
}

// ** versions

.#{$class-prefix}-dropdown--inline {
    display: inline-block;
    height: getGrid(8px);
    margin: 0;

    .#{$class-prefix}-dropdown_select {
        @include getFontSize(body);

        margin: 0;
    }
}

.#{$class-prefix}-dropdown--simple {
    display: inline-block;
    flex: initial;
    max-width: calc(100% - 4px);
    width: auto;
    height: getGrid(6px);
    margin: 0;
    border: none;
    padding-right: getGrid(6px);
    padding-top: getGrid(1px);

    .#{$class-prefix}-dropdown_label,
    label {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .#{$class-prefix}-icon {
        top: -2px;
        right: getGrid(-2px);
        width: getGrid(8px);
        min-width: getGrid(8px);
        min-height: getGrid(8px);
        height: getGrid(8px);
        padding: 0;
        background: getColor(content);
    }

    .#{$class-prefix}-dropdown_select {
        margin: 0;
        border: none;
        color: transparent;

        @include getFontSize(caption);
    }
}

.#{$class-prefix}-dropdown--active.#{$class-prefix}-dropdown--simple {
    .#{$class-prefix}-dropdown_label,
    label {
        background: getColor(content);
        transform: translate(0, 0) scale(1);
        font-weight: 400;
    }

    .#{$class-prefix}-dropdown_select {
        color: getColor(text);
        bottom: 0;
    }
}

// this modifier class is a hack to hide the drop down icon
// and disable pointer events when there is a single option for drop down
.#{$class-prefix}-dropdown--single {
    pointer-events: none;

    .#{$class-prefix}-required,
    .#{$class-prefix}-icon--selectbox {
        opacity: 0 !important;
    }
}
