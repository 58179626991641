/**
 *
 * @group Components
 * @subgroup Subheader
 *
 * @description Subheaders delineate distinct sections of a list, grid list or a menu.
 *
 * @base .#{$class-prefix}-subheader Subheader
 *
 * @modifier .#{$class-prefix}-subheader--dense Dense subheader
 * @modifier
 *
 * @example <h3 class="pb-subheader [modifierClass]">[description]</h3>
 *
 * @markup
 * <h3 class="pb-subheader [modifierClass]">Subheader title</h3>
 *
 */

.#{$class-prefix}-subheader {
    overflow: hidden;
    margin: 0;
    padding: getGrid(3px) getGrid(4px) 0 getGrid(4px);
    height: getGrid(12px);
    color: getColor(secondarytext);
    text-overflow: ellipsis;

    @include getTypography(body-2);
}

.#{$class-prefix}-subheader--dense {
    height: getGrid(9px);
}
