/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Helper
 */

// *** visibility
.#{$class-prefix}-is-hidden {
    display: none;
}

.#{$class-prefix}-is-block {
    display: block;
}

.#{$class-prefix}-is-inlineblock {
    display: inline-block;
}

// *** make text selectable
.#{$class-prefix}-is-selectable {
    user-select: initial;
}

// *** alignment
.#{$class-prefix}-is-center {
    text-align: center;
}

.#{$class-prefix}-is-left {
    text-align: left;
}

.#{$class-prefix}-is-right {
    text-align: right;
}

.#{$class-prefix}-is-centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// *** box
.#{$class-prefix}-is-overflow {
    overflow: auto !important;
}

.#{$class-prefix}-no-overflow {
    overflow: hidden !important;
}

.#{$class-prefix}-radius {
    border-radius: getRadius(radius);
}

.#{$class-prefix}-radius_bottom {
    border-bottom-left-radius: getRadius(radius);
    border-bottom-right-radius: getRadius(radius);
}

.#{$class-prefix}-radius_top {
    border-top-left-radius: getRadius(radius);
    border-top-right-radius: getRadius(radius);
}

// *** position
.#{$class-prefix}-is-static {
    position: static !important;
}

.#{$class-prefix}-is-absolute {
    position: absolute !important;
}

.#{$class-prefix}-is-relative {
    position: relative !important;
}

.#{$class-prefix}-is-fixed {
    position: fixed !important;
}

.#{$class-prefix}-no-transform {
    transform: initial !important;
}

// text
.#{$class-prefix}-is-multiline {
    overflow: auto !important;
    white-space: normal !important;
}

.#{$class-prefix}-is-singleline {
    overflow: hidden !important;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}

// links
.#{$class-prefix}-is-link {
    cursor: pointer;
}

.#{$class-prefix}-is-nolink {
    background-color: rgba(0, 0, 0, 0);
    cursor: default;

    &:active {
        color: inherit;
    }
}

// screen positions for fabs
.#{$class-prefix}-is-topleft {
    position: absolute;
    top: getGrid(4px);
    left: getGrid(4px);
}

.#{$class-prefix}-is-topright {
    position: absolute;
    top: getGrid(4px);
    right: getGrid(4px);
}

.#{$class-prefix}-is-bottomleft {
    position: absolute;
    bottom: getGrid(4px);
    left: getGrid(4px);
}

.#{$class-prefix}-is-bottomright {
    position: absolute;
    right: getGrid(4px);
    bottom: getGrid(4px);
}

// width
.#{$class-prefix}-is-fullwidth {
    width: 100%;
    min-width: 100%;
}

// height
.#{$class-prefix}-is-fullheight {
    height: 100%;
    min-height: 100%;
}

// force display of full text
.#{$class-prefix}-is-linebreak {
    display: block;
    white-space: normal;
}

// secondary action in lists
.#{$class-prefix}-is-secondary {
    margin-right: getGrid(-1px);
}

// element indentation
.#{$class-prefix}-is-indent::before {
    width: getGrid(14px);
    content: '';
}

// states
.#{$class-prefix}-is-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

// *** cover content
.#{$class-prefix}-is-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

// *** hacks
.#{$class-prefix}-h-scrollfix {
    overflow: hidden;
    //  position: fixed;
}

.#{$class-prefix}-is-transparent {
    opacity: 0;
}
