/**
 *
 * @group Components
 * @subgroup Drawer
 *
 * @description The Drawer slides in from the side. It is a common pattern found in Google apps and follows the keylines and metrics for lists.
 *
 * @base .#{$class-prefix}-drawer Drawer
 *
 * @modifier .#{$class-prefix}-drawer--animate Drawer animation | Animation to show/hide the drawer
 * @modifier
 *
 * @example <div class="pb-drawer [modifierClass]">... TEMPLATE</div>
 *
 *
 */

.#{$class-prefix}-drawer {
    // hack, because of scrims /2210) has to be above fullscreen (2200), the drawer
    // has to be above the scrim
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    max-width: getGrid(80px);
    width: calc(100vw - #{getGrid(14px)});
    min-width: 264px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: getColor(content);
    opacity: 1;
    transition: transform 0.9s getAnimation(ease);
    transform: translate3d(calc(-100% - 16px), 0, 0);
    will-change: transform;

    @include getDepth(16);

    user-select: none;
}

.#{$class-prefix}-drawer--animate {
    transform: translate3d(0, 0, 0);
}

.#{$class-prefix}-drawer--static {
    position: initial;
    top: initial;
    left: initial;
    transition: initial;
    transform: initial;
    height: auto;

    @include getDepth(0);

    border-right: 1px solid getColor(dividers);
}

.#{$class-prefix}-drawer--static::after {
    position: initial;
    top: initial;
    right: initial;
    display: block;
    width: auto;
    height: auto;
    background: initial;
    content: '';
}
