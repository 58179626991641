/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Dark theme classes
 */

.#{$class-prefix}-color_dark-statusbar {
    background-color: getColor(statusbar, dark) !important;
}

.#{$class-prefix}-color_dark-appbar {
    background-color: getColor(appbar, dark) !important;
}

.#{$class-prefix}-color_dark-background {
    background-color: getColor(background, dark) !important;
}

.#{$class-prefix}-color_dark-content {
    background-color: getColor(content, dark) !important;
}

.#{$class-prefix}-color-text_light {
    color: getColor(text, light) !important;
    fill: getColor(text, light) !important;
}

.#{$class-prefix}-color-text_light-secondary {
    color: getColor(secondarytext, light) !important;
    fill: getColor(secondarytext, light) !important;
}

.#{$class-prefix}-color-text_light-disabled {
    color: getColor(disabled, light) !important;
}

.#{$class-prefix}-color-text_light-dividers {
    color: getColor(dividers, light) !important;
}

// experimental, not ready
.#{$class-prefix}-dark {
    background-color: getColor(background, dark) !important;
    color: getColor(text, dark);

    .#{$class-prefix}-card,
    .#{$class-prefix}-dialog {
        background-color: getColor(content, dark) !important;
    }

    .#{$class-prefix}-color_light-content,
    .#{$class-prefix}-color_content,
    .#{$class-prefix}-drawer {
        background: getColor(content, dark) !important;
    }

    .#{$class-prefix}-list_body,
    .#{$class-prefix}-list_body2 {
        color: getColor(secondarytext, light);
    }
}
