/**
 *
 * @group Components
 * @subgroup Progress & activity
 *
 * @base .#{$class-prefix}-spinner Circular indeterminate progress indicators
 *
 * @modifier .#{$class-prefix}-spinner--fixed Fixed progress | Floats above other gui-elements
 * @modifier .#{$class-prefix}-spinner--raised Fixed progress | Floats above other gui-elements
 * @modifier .#{$class-prefix}-spinner--mini Fixed progress | Floats above other gui-elements
 *
 * @example <div class="pb-spinner [modifierClass]"><svg class="pb-spinner_icon" viewBox="28 28 44 44"><circle class="pb-spinner_path" cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10"/></svg></div>
 *
 * @markup
 * <div class="pb-spinner [modifierClass]">
 *    <svg class="pb-spinner_icon" viewBox="28 28 44 44">
 *        <circle class="pb-spinner_path" cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10" />
 *    </svg>
 * </div>
 *
 */

// *** spinner
$md-spinner-speed: 1s;
$md-spinner-stroke: 4px;
$md-spinner-duration: 2.4s;

.#{$class-prefix}-spinner {
    display: inline-block;
    width: getGrid(6.5px);
    height: getGrid(6.5px);
    border-radius: 50%;
    text-align: center;
}

.#{$class-prefix}-spinner_icon {
    animation: animation-rotate360 $md-spinner-duration linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    margin: auto;
}

.#{$class-prefix}-spinner_path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke: getColor(primary);
    stroke-width: $md-spinner-stroke;
    animation: animation-dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes animation-rotate360 {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animation-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

.#{$class-prefix}-spinner.#{$class-prefix}-spinner--raised {
    width: getGrid(8.5px);
    height: getGrid(8.5px);
    background-color: getColor(content);
    padding: getGrid(1px);

    @include getDepth(1);
}

.#{$class-prefix}-spinner--raised.#{$class-prefix}-spinner--mini {
    padding: getGrid(1px);
    width: getGrid(7px) !important;
    height: getGrid(7px) !important;
}

.#{$class-prefix}-spinner.#{$class-prefix}-spinner--fixed {
    position: fixed;
    top: initial;
    left: 50%;
    transform: translateX(-50%);
}

.#{$class-prefix}-spinner.#{$class-prefix}-spinner--mini {
    width: getGrid(5px);
    height: getGrid(5px);

    .#{$class-prefix}-spinner_path {
        stroke-width: $md-spinner-stroke;
    }
}

.#{$class-prefix}-spinner--light {
    .#{$class-prefix}-spinner_path {
        stroke: getColor(secondarytext, light) !important;
    }
}

.#{$class-prefix}-spinner--dark {
    .#{$class-prefix}-spinner_path {
        stroke: getColor(secondarytext, dark) !important;
    }
}

$md-spinner-stroke: 2px;

.#{$class-prefix}-spinner--fab {
    width: getGrid(16px);
    height: getGrid(16px);
    margin-top: getGrid(-1px);
    margin-left: getGrid(-1px);

    @include getDepth(13);

    .#{$class-prefix}-spinner_path {
        stroke: getColor(secondary, dark);
        stroke-width: $md-spinner-stroke;
    }
}

.#{$class-prefix}-spinner--fab.#{$class-prefix}-spinner--mini {
    width: getGrid(11px);
    height: getGrid(11px);
    margin-top: getGrid(-0.5px);
    margin-left: getGrid(-0.5px);

    @include getDepth(13);

    .#{$class-prefix}-spinner_path {
        stroke-width: $md-spinner-stroke;
    }
}
