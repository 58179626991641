/**
 *
 * @group Components
 * @subgroup Chip
 *
 * @description Chips represent complex entities in small blocks, such as a filter item. They can contain a short title string and an delete icon.
 *
 * @base .#{$class-prefix}-chip Default chip
 *
 * @modifier .#{$class-prefix}-chip--dense Dense chip | A smaller version of a chip.
 * @modifier .#{$class-prefix}-chip--square Less rounded chip | The corner of the chips are rather squared.
 * @modifier .#{$class-prefix}-chip--light Light theme
 * @modifier .#{$class-prefix}-chip--primary Primary theme
 *
 * @state :active Active clicked chip
 * @state
 *
 * @example <div class="pb-chip [modifierClass]">[description]</div>
 *
 * @markup
 * <div class="pb-chip [modifierClass]">[description]</div>
 *
 */

/**
 *
 * @group Components
 * @subgroup Chip
 *
 *
 * @base .#{$class-prefix}-chip--delete Chip with icon delete
 *
 * @example <div class="pb-chip pb-chip--delete">[description] <svg viewBox="-4 -4 32 32" class="pb-icon pb-icon--chipdelete"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-close"></use></svg></div>
 *
 * @markup
 * <div class="pb-chip pb-chip--delete">[description]
 *    <svg viewBox="-4 -4 32 32" class="pb-icon pb-icon--chipdelete">
 *        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-close"></use>
 *    </svg>
 * </div>
 *
 */

/**
 *
 * @group Components
 * @subgroup Chip
 *
 * @base .#{$class-prefix}-chip--icon Chip with icon
 *
 * @example <div class="pb-chip pb-chip--icon"><svg viewBox="-4 -4 32 32" class="pb-icon loyalty_color pb-icon--premium"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-account_circle"></use></svg>[description]</div>
 *
 * @markup
 * <div class="pb-chip pb-chip--icon">
 *    <svg viewBox="-4 -4 32 32" class="pb-icon loyalty_color pb-icon--premium">
 *        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-account_circle"></use>
 *    </svg>
 *    [description]
 * </div>
 *
 */

.#{$class-prefix}-chip:not(.#{$class-prefix}-chip--active):not(.#{$class-prefix}-chip--outline) {
    background-color: getColor(dividers);

    &:active {
        background-color: getColor(disabled);

        @include getDepth(2);
    }
}

.#{$class-prefix}-chip {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: getGrid(1px);
    padding: getGrid(0) getGrid(1px);
    width: auto;
    min-height: getGrid(8px);
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: initial;
    border: none;
    border-radius: getGrid(8px);
    user-select: none;

    @include getTransition(0.3s);

    .#{$class-prefix}-icon {
        min-width: getGrid(6px);
        width: getGrid(6px);
        min-height: getGrid(6px);
        height: getGrid(6px);
        padding: 0;

        // content icon
        &:first-of-type {
            margin-left: getGrid(0.5px);
        }
    }

    // action icon
    $chip_action-size: getGrid(4px);

    .#{$class-prefix}-chip_label + .#{$class-prefix}-icon {
        margin: 0 getGrid(1px) 0 0;
        min-width: $chip_action-size;
        width: $chip_action-size;
        height: $chip_action-size;
        min-height: $chip_action-size;
    }
}

.#{$class-prefix}-chip_label {
    flex: 1;
    padding: 0 getGrid(2px);
    color: getColor(secondarytext);

    @include getTypography(chip);

    text-decoration: none;
    letter-spacing: 0;
}

.#{$class-prefix}-chip--active {
    background-color: getColor(primary, light);

    .#{$class-prefix}-chip_label {
        color: getColor(primary);
    }

    &:active {
        background-color: getColor(primary, active);

        .#{$class-prefix}-chip_label {
            color: getColor(text, primary);
        }
    }
}

.#{$class-prefix}-chip--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.#{$class-prefix}-chip--outline {
    border: 1px solid getColor(disabled);

    &:active {
        background-color: getColor(dividers);
        color: getColor(secondarytext);
    }
}

.#{$class-prefix}-chip--square {
    border-radius: getRadius(radius);

    .#{$class-prefix}-icon {
        border-radius: inherit;
    }
}

.#{$class-prefix}-chip--dense {
    min-height: getGrid(6px);

    .#{$class-prefix}-chip_label {
        font-size: getGrid(3px);
        padding: 0 getGrid(1px);
    }

    .#{$class-prefix}-chip_label + .#{$class-prefix}-icon {
        margin: 0 0 0 getGrid(1px);
    }
}

// *** theming

.#{$class-prefix}-chip--light {
    background-color: getColor(background, light);
    color: getColor(text);
}

.#{$class-prefix}-chip--primary {
    background-color: getColor(primary);
    color: getColor(text, primary);

    .#{$class-prefix}-icon:first-of-type {
        fill: getColor(text, light);
    }

    .#{$class-prefix}-chip_label + .#{$class-prefix}-icon {
        background-color: getColor(primary, dark);
        fill: getColor(secondarytext, light);
    }
}
