/**
 *
 * @group Components
 * @subgroup Icon
 *
 * @description An icon symbolizes an action or emphasize a text description. Icons are commonly used in toolbars and lists.
 *
 * @base .#{$class-prefix}-icon Default icon
 *
 * @modifier .#{$class-prefix}-icon--expand Expanded icon
 * @modifier .#{$class-prefix}-icon--inline Inline icon
 * @modifier .#{$class-prefix}-icon--dense Compact icon
 * @modifier .#{$class-prefix}-icon--square Squared background icon
 * @modifier .#{$class-prefix}-icon--big Big icon
 * @modifier .#{$class-prefix}-icon--large Large icon
 * @modifier .#{$class-prefix}-icon--invert Inverted icon
 * @modifier .#{$class-prefix}-icon--popup Rotated icon
 * @modifier .#{$class-prefix}-icon--listsecondary List icon
 *
 * @example <svg class="pb-icon [modifierClass]" viewBox="-4 -4 32 32"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-account_circle"></use></svg>
 *
 * @markup
 * <svg class="pb-icon [modifierClass]" viewBox="-4 -4 32 32">
 *  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-shopping_cart"></use>
 * </svg>
 *
 */

.#{$class-prefix}-icon {
    display: inline-block;
    min-width: getGrid(12px);
    min-height: getGrid(12px);
    width: getGrid(12px);
    height: getGrid(12px);
    padding: getGrid(2px);
    fill: getColor(secondarytext);
    //  padding: getGrid(3px);
    border-radius: 50%;
    transition: 0.3s;
    user-select: none;

    &.#{$class-prefix}-is-secondary {
        fill: getColor(disabled);
    }

    &.#{$class-prefix}-is-primary {
        fill: getColor(primary);
    }
}

a,
button {
    .#{$class-prefix}-icon:active {
        background-color: getColor(dividers);
        text-decoration: none;
    }
}

.#{$class-prefix}-icon--active {
    background-color: getColor(dividers);
    text-decoration: none;
}

// class for inline icon like in links
.#{$class-prefix}-icon--inline {
    padding: 0;
    min-width: 1.5em;
    width: 1.5em;
    min-height: 1.5em;
    height: 1.5em;
    border-radius: 0;
    vertical-align: middle;
}

.#{$class-prefix}-icon--circle {
    border-radius: 50%;
    background: getColor(dividers);
}

.#{$class-prefix}-icon--square {
    border-radius: getRadius(radius);
    background: getColor(dividers);
}

.#{$class-prefix}-icon--invert {
    margin: getGrid(3.5px);
    padding: 0;
    min-width: getGrid(5px);
    width: getGrid(5px);
    height: getGrid(5px);
    min-height: getGrid(5px);
    background-color: getColor(secondarytext);
    fill: getColor(text, light);
}

.#{$class-prefix}-icon--dense {
    margin: getGrid(1px);
    padding: getGrid(1px);
    min-width: getGrid(8px);
    width: getGrid(8px);
    height: getGrid(8px);
    min-height: getGrid(8px);
}

.#{$class-prefix}-icon--large {
    padding: 0;
}

.#{$class-prefix}-icon--big {
    min-width: getGrid(14px);
    width: getGrid(14px);
    height: getGrid(14px);
    min-height: getGrid(14px);
}

// special case for expandable
.#{$class-prefix}-icon--expand {
    fill: getColor(disabled);
    cursor: pointer;
    transition: transform 0.6s getAnimation(ease);
    transform: rotateZ(0deg);
}

.#{$class-prefix}-icon--expand-animate {
    transform: rotateZ(180deg);
}

// button for list action, right aligned
.#{$class-prefix}-icon--expand,
.#{$class-prefix}-icon--listaction {
    position: relative;
    right: getGrid(-1px);
}

// special case for vertical more
.#{$class-prefix}-icon--narrow {
    min-width: getGrid(8px);
    width: getGrid(8px);
    height: getGrid(8px);
    min-height: getGrid(8px);
    padding: 0;
}

.#{$class-prefix}-icon--listsecondary {
    fill: getColor(disabled);
    min-width: getGrid(9px);
    width: getGrid(9px);
    height: getGrid(9px);
    min-height: getGrid(9px);
}

.#{$class-prefix}-icon--popup {
    transform: rotate(315deg) translateY(4px);
    padding: 0;
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    text-rendering: geometricPrecision;
}
