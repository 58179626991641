/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Levels for z-plane
 */

/*
 *
 * 24
 * Dialog
 * Picker
 *
 * 22
 * inofficial
 * Fullscreen Dialog
 *
 * 16
 * Nav drawer
 * Right drawer
 * Modal bottom Sheet
 *
 * 12
 * Floating action button (FAB - pressed)
 *
 * 9
 * Sub menu (+1dp for each sub menu)
 *
 * 8
 * Menu
 * Card (picked up state)
 * Raised button (pressed state)
 *
 * 7
 * Scrim
 *
 * 6
 * Floating action button (FAB - resting elevation)
 * Snackbar
 *
 * 5
 * Bottom navigation
 *
 * 4
 * App Bar
 *
 * 3
 * Refresh indicator
 * Quick entry / Search bar (scrolled state)
 *
 * 2
 * Card (resting elevation)
 * Raised button (resting elevation)
 * Quick entry / Search bar (resting elevation)
 *
 * 1
 * switch
 *
 */

$map-depths: (
    0: 'none',
    1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    2: '0 1px 3px rgba(0,0,0,0.15),0 1px 5px rgba(0,0,0,0.2)',
    3: '0 2px 4px rgba(0,0,0,0.25),0 4px 8px rgba(0,0,0,0.15)',
    4: '0 2px 4px rgba(0,0,0,0.1),0 4px 10px rgba(0,0,0,0.15)',
    5: '0 -3px 6px rgba(0, 0, 0, 0.25)',
    6: '0 6px 6px rgba(0,0,0,0.2),0 1px 16px rgba(0,0,0,0.1)',
    8: '0 2px 16px rgba(0,0,0,0.3),0 12px 16px rgba(0,0,0,0.15)',
    9: '0 8px 14px rgba(0,0,0,0.25), 0 16px 18px rgba(0,0,0,0.05)',
    12: '0 10px 10px rgba(0,0,0,0.1),0 12px 18px rgba(0,0,0,0.15)',
    16: '6px 0 20px rgba(0,0,0,0.2)',
    22: '0 8px 36px rgba(0,0,0,0.3),0 24px 24px rgba(0,0,0,0.15)',
    24: '0 8px 36px rgba(0,0,0,0.3),0 24px 24px rgba(0,0,0,0.15)'
);

/**
 * @module Depths mixin (shadow and z-index)
 */

// print shadow and z-index
@mixin getDepth($level, $important: '') {
    @if map-has-key($map-depths, $level) {
        box-shadow: unquote(map-get($map-depths, $level)) unquote($important);
    }

    z-index: $level * 100;
}
