/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Theming on elelents dark
 */

@mixin tmSchemeText(
    $theme_scheme: light,
    $theme_scheme-main: light,
    $theme_scheme-counter: dark
) {
    // mapper for scheme colors
    // $theme_background: getColor(background, $theme_scheme-main);

    $theme_background: getColor(background, $theme_scheme-main);
    $theme_background2: getColor(background, $theme_scheme-counter);

    $theme_appbar: getColor(appbar, $theme_scheme-main);

    $theme_content: getColor(content, $theme_scheme-main);
    $theme_content2: getColor(content, $theme_scheme-counter);

    // text
    $theme_text: getColor(text, $theme_scheme-counter);
    $theme_text2: getColor(text, $theme_scheme-main);
    $theme_text-light: getColor(text, light);
    $theme_secondarytext: getColor(secondarytext, $theme_scheme-counter);
    $theme_secondarytext2: getColor(secondarytext, $theme_scheme-main);
    $theme_disabled: getColor(disabled, $theme_scheme-counter);
    $theme_disabled2: getColor(disabled, $theme_scheme-main);
    $theme_dividers: getColor(dividers, $theme_scheme-counter);
    $theme_primary-dark: getColor(primary, dark);
    $theme_primary: getColor(primary);
    $theme_secondary: getColor(secondary);

    $theme_grey100: getColor(grey, '100-' + $theme_scheme-main);
    $theme_grey200: getColor(grey, '200-' + $theme_scheme-main);
    $theme_grey300: getColor(grey, '300-' + $theme_scheme-main);
    $theme_grey400: getColor(grey, '400-' + $theme_scheme-main);
    $theme_grey500: getColor(grey, '500-' + $theme_scheme-main);
    $theme_grey600: getColor(grey, '600-' + $theme_scheme-main);
    $theme_grey700: getColor(grey, '700-' + $theme_scheme-main);
    $theme_grey800: getColor(grey, '800-' + $theme_scheme-main);
    $theme_grey900: getColor(grey, '900-' + $theme_scheme-main);

    // light text on dark background
    .#{$class-prefix}-theme_#{$theme_scheme} {
        color: $theme_secondarytext;

        .#{$class-prefix}-bottomnav_button--active
            .#{$class-prefix}-icon--bottomnav,
        .#{$class-prefix}-bottomnav_button--active
            .#{$class-prefix}-bottomnav_label,
        .#{$class-prefix}-bottomnav_button:active
            .#{$class-prefix}-icon--bottomnav,
        .#{$class-prefix}-bottomnav_button:active
            .#{$class-prefix}-bottomnav_label,
        .#{$class-prefix}-toolbar,
        input,
        textarea,
        .#{$class-prefix}-textfield_input,
        .#{$class-prefix}-tabs_tab--active,
        .#{$class-prefix}-tabs_tab:active,
        .#{$class-prefix}-dropdown_select {
            color: $theme_text;
            fill: $theme_text;
        }

        .#{$class-prefix}-color-text {
            color: $theme_text !important;
            fill: $theme_text !important;
        }

        .#{$class-prefix}-chip:not(.#{$class-prefix}-chip--active)
            .#{$class-prefix}-chip_label,
        .#{$class-prefix}-subheader,
        .#{$class-prefix}-bottomnav_label,
        .#{$class-prefix}-icon,
        .#{$class-prefix}-list,
        .#{$class-prefix}-table,
        .#{$class-prefix}-bottomsheet,
        .#{$class-prefix}-drawer,
        .#{$class-prefix}-label label,
        .#{$class-prefix}-hint {
            color: $theme_secondarytext;
            fill: $theme_secondarytext;
        }

        .#{$class-prefix}-color-text_secondary {
            color: $theme_secondarytext !important;
            fill: $theme_secondarytext !important;
        }

        .#{$class-prefix}-icon--circle,
        .#{$class-prefix}-icon--invert {
            color: $theme_secondarytext2;
            fill: $theme_secondarytext2;
        }

        ::-webkit-input-placeholder,
        ::-moz-placeholder,
        :-ms-input-placeholder,
        input:-moz-placeholder {
            color: $theme_secondarytext;
        }

        .#{$class-prefix}-button--disabled,
        .#{$class-prefix}-tabs_tab {
            color: $theme_disabled;
            fill: $theme_disabled;
        }

        .#{$class-prefix}-color-text_disabled {
            color: $theme_disabled !important;
            fill: $theme_disabled !important;
        }

        .#{$class-prefix}-dropdown_select {
            border-bottom: 1px solid $theme_disabled;
        }

        // ** the rest of it
        .#{$class-prefix}-divider {
            border-bottom-color: $theme_dividers;
        }

        .#{$class-prefix}-chip:not(.#{$class-prefix}-chip--active):not(.#{$class-prefix}-chip--outline) {
            background-color: $theme_dividers;

            &:active {
                background-color: $theme_disabled;
            }
        }

        .#{$class-prefix}-icon--circle {
            background-color: $theme_secondarytext;

            &:active {
                background-color: $theme_grey700;
            }
        }

        .#{$class-prefix}-button--text {
            color: getContrastColor(
                $theme_background,
                $theme_primary,
                getColor(primary, contrast)
            );

            .#{$class-prefix}-icon {
                fill: getContrastColor(
                    $theme_background,
                    $theme_primary,
                    getColor(primary, contrast)
                );
            }
        }

        .#{$class-prefix}-textfield {
            input,
            textarea,
            .#{$class-prefix}-textfield_input {
                border-bottom: 1px solid $theme_secondarytext;

                &:focus {
                    border-bottom: 2px solid $theme_primary;
                }
            }
        }
    }

    // ** tables
    .#{$class-prefix}-theme_#{$theme_scheme} .#{$class-prefix}-table {
        caption {
            color: $theme_text;
        }

        tbody {
            tr {
                &.#{$class-prefix}-is-selected {
                    color: $theme_text;
                }

                &:hover {
                    color: $theme_text;
                }
            }
        }

        th {
            color: $theme_text;
        }
    }

    // ** list
    .#{$class-prefix}-theme_#{$theme_scheme} .#{$class-prefix}-list {
        &.#{$class-prefix}-list--is-link .#{$class-prefix}-list_item:active,
        &.#{$class-prefix}-list--is-link
            .#{$class-prefix}-list_item--medium:active {
            color: $theme_text;
        }
        // *** active list
        .#{$class-prefix}-list_item--active {
            color: $theme_text;
            fill: $theme_text;

            .#{$class-prefix}-icon {
                fill: $theme_text;
            }
        }

        &.#{$class-prefix}-list--divider {
            .#{$class-prefix}-list_item {
                border-top-color: $theme_dividers;
            }
        }
    }

    // ** stepper
    .#{$class-prefix}-theme_#{$theme_scheme} .#{$class-prefix}-stepper {
        .#{$class-prefix}-stepper_step-circle {
            color: $theme_text;

            .#{$class-prefix}-icon {
                fill: $theme_text;
            }
        }

        .pb-stepper_step:after,
        .pb-stepper_step:before {
            background-color: $theme_dividers;
        }

        .#{$class-prefix}-stepper_step--active {
            .#{$class-prefix}-stepper_step-circle {
                background-color: $theme_text;
                color: $theme_text2;

                .#{$class-prefix}-icon {
                    fill: $theme_text2;
                }
            }

            .#{$class-prefix}-stepper_step-label {
                color: $theme_text;
            }
        }

        .#{$class-prefix}-stepper_step-label {
            color: $theme_secondarytext;
        }
    }
}
