/**
 *
 * @group Components
 * @subgroup Pagination
 *
 *
 * @base .#{$class-prefix}-pagination Unfinished component
 *
 *
 */

.#{$class-prefix}-pagination {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: getGrid(1px);
    min-height: getGrid(12px);
    width: 100%;
    list-style-type: none;
    align-items: center;
}

.#{$class-prefix}-pagination_navigation {
    margin: 0;
    padding: 0;
    color: getColor(text, light);

    .#{$class-prefix}-icon {
        fill: getColor(text, light) !important;
    }

    &:first-of-type {
        padding-right: getGrid(2px);
    }

    &:last-of-type {
        padding-left: getGrid(2px);
    }
}

.#{$class-prefix}-pagination_navigation--disabled {
    color: getColor(disabled, light);

    .#{$class-prefix}-icon {
        fill: getColor(disabled, light) !important;
    }
}

.#{$class-prefix}-pagination_page {
    margin: 0;
    padding: 0 1px 0 0;
    text-align: center;
    flex: 1;
}

.#{$class-prefix}-pagination_link {
    margin: 0;
    padding: 0;
    width: getGrid(6px);
    height: getGrid(6px);
    border-radius: 50%;
    background-color: transparent;
    color: getColor(secondarytext, light);
    text-align: center;

    &:active {
        background-color: getColor(disabled, dark);
        text-decoration: none;
    }
}

.#{$class-prefix}-pagination_link--active {
    background-color: getColor(text, light);
    color: getColor(primary, contrast);

    @include getTypography(subheading-2);

    text-decoration: none;
    line-height: initial;
}
