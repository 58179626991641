/**
 *
 * @group Components
 * @subgroup Form
 *
 * @description Forms are the standard way to receive user inputted data.
 *
 * @base .#{$class-prefix}-form Default form
 *
 * @example <div class="pb-form">...FIELDS</div>
 *
 *
 */

.#{$class-prefix}-form {
  display: block;
  margin: 0;
  padding: 0;
}

input,
textarea,
select {
  font-family: getTypography(font);
}

label {
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: auto;
  height: getGrid(6px);

  @include getTransition();
  @include getTypography(body-1);
  @include getFontSize(subheading);

  color: getColor(secondarytext);
}

.#{$class-prefix}-label {
  overflow: hidden;
  margin: 0 getGrid(2px);
  padding: 0;
  height: auto;

  @include getTypography(body-1);
  @include getFontSize(subheading);

  color: getColor(secondarytext);
}

.#{$class-prefix}-label--gridrow {
  height: auto;
  margin: getGrid(4px) 0 getGrid(-1px) 0;
}

.#{$class-prefix}-required {
  @include getTypography(caption);

  position: relative;
  top: getGrid(-1px);
  left: getGrid(1px);
}

.#{$class-prefix}-form_group {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  margin: getGrid(4px) getGrid(4px);
  border: none;
  padding: 0;
  height: auto;
  flex: 1;
  min-width: auto;
}

// placeholder
::-webkit-input-placeholder {
  color: getColor(disabled);
}

::-moz-placeholder {
  color: getColor(disabled);
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: getColor(disabled);
}

/* ie */
:-moz-placeholder {
  color: getColor(disabled);
}

// placeholder
::placeholder {
  color: getColor(disabled);
}
