/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Animation helper
 */

.#{$class-prefix}-rotate {
    transform: rotateZ(180deg);
    transform-origin: center;
}

.#{$class-prefix}-rotate_90 {
    transform: rotateZ(90deg);
    transform-origin: center;
}

.#{$class-prefix}-ani_fadein {
    opacity: 0;
}

.#{$class-prefix}-ani_fadein--animate {
    animation: #{$class-prefix}-animation--fadein 1.6s getAnimation(ease);
}

@keyframes #{$class-prefix}-animation--fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
