/**
 *
 * @group Components
 * @subgroup Avatar
 *
 * @description An avatar symbolizes an action or emphasize a text description. avatars are commonly used in toolbars and lists.
 *
 * @base .#{$class-prefix}-avatar Default avatar
 *
 * @modifier .#{$class-prefix}-avatar--small 24px avatar
 * @modifier .#{$class-prefix}-avatar--mediumsmall 36px avatar
 * @modifier .#{$class-prefix}-avatar--medium 48px avatar
 * @modifier .#{$class-prefix}-avatar--large 64px avatar
 * @modifier .#{$class-prefix}-avatar--extralarge 96px avatar
 * @modifier .#{$class-prefix}-avatar--square Squared avatar
 *
 * @example <div class="pb-avatar><img src="..."></div>
 *
 * @markup
 * <div class="pb-avatar><img src="..."></div>
 *
 */

.pb-avatar {
    display: inline-block;
    border-radius: 50%;
    width: getGrid(12px);
    height: getGrid(12px);

    * {
        border-radius: inherit;
        width: inherit;
        height: inherit;
        object-fit: cover;
    }
}

.pb-avatar--extralarge {
    width: getGrid(24px);
    height: getGrid(24px);
}

.pb-avatar--large {
    width: getGrid(18px);
    height: getGrid(18px);
}

.pb-avatar--medium {
    width: getGrid(12px);
    height: getGrid(12px);
}

.pb-avatar--mediumsmall {
    width: getGrid(8px);
    height: getGrid(8px);
}

.pb-avatar--small {
    width: getGrid(6px);
    height: getGrid(6px);
}

.pb-avatar--square {
    border-radius: 0;
}
