/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Fonts import and font classes
 */

// import font weights
@font-face {
    font-style: normal;
    font-family: '#{$typography-font}';
    src: local('Roboto Regular'),
        url('#{getTypography(font, path)}/Roboto.woff2') format('woff2'),
        url('#{getTypography(font, path)}/Roboto.woff') format('woff'),
        url('#{getTypography(font, path)}/Roboto.ttf') format('truetype');

    @include getFontWeight(normal);
}

@font-face {
    font-style: normal;
    font-family: '#{$typography-font}';
    src: local('Roboto Medium'),
        url('#{getTypography(font, path)}/Roboto-Medium.woff2') format('woff2'),
        url('#{getTypography(font, path)}/Roboto-Medium.woff') format('woff'),
        url('#{getTypography(font, path)}/Roboto-Medium.ttf') format('truetype');

    @include getFontWeight(medium);
}

@font-face {
    font-weight: bold;
    font-style: normal;
    font-family: '#{$typography-font}';
    src: local('Roboto Medium'),
        url('#{getTypography(font, path)}/Roboto-Medium.woff2') format('woff2'),
        url('#{getTypography(font, path)}/Roboto-Medium.woff') format('woff'),
        url('#{getTypography(font, path)}/Roboto-Medium.ttf') format('truetype');
}

.#{$class-prefix}-font {
    font-family: getTypography(font);
}

.#{$class-prefix}-font_weight,
.#{$class-prefix}-font_weight-regular {
    @include getFontWeight(medium);
}

.#{$class-prefix}-font_weight-bold,
.#{$class-prefix}-font_weight-medium {
    @include getFontWeight(medium);
}

// font-size classes
.#{$class-prefix}-typo_size {
    @include getFontSize(body);
}

.#{$class-prefix}-typo_size--secondary {
    @include getFontSize(secondary);
}

.#{$class-prefix}-typo_size--caption {
    @include getFontSize(caption);
}

.#{$class-prefix}-typo_size--body {
    @include getFontSize(body);
}

.#{$class-prefix}-typo_size--subheading {
    @include getFontSize(subheading);
}

.#{$class-prefix}-typo_size--title {
    @include getFontSize(title);
}

.#{$class-prefix}-typo_size--headline {
    @include getFontSize(headline);
}

.#{$class-prefix}-typo_size--display1 {
    @include getFontSize(display1);
}

.#{$class-prefix}-typo_size--display2 {
    @include getFontSize(display2);
}

.#{$class-prefix}-typo_size--display3 {
    @include getFontSize(display3);
}

.#{$class-prefix}-typo_size--display4 {
    @include getFontSize(display4);
}

// typo classes
.#{$class-prefix}-typo_display-4 {
    @include getTypography(display4);
}

.#{$class-prefix}-typo_display-3 {
    @include getTypography(display3);
}

.#{$class-prefix}-typo_display-2 {
    @include getTypography(display2);
}

.#{$class-prefix}-typo_display-1 {
    @include getTypography(display1);
}

.#{$class-prefix}-typo_headline {
    @include getTypography(headline);
}

.#{$class-prefix}-typo_title {
    @include getTypography(title);
}

.#{$class-prefix}-typo_subheading {
    @include getTypography(subheading);
}

.#{$class-prefix}-typo_subheading.#{$class-prefix}-typo--xhigh {
    @include getFontLineheight(subheading-2, !important);
}

.#{$class-prefix}-typo_subheading-2 {
    @include getTypography(subheading-2);
}

.#{$class-prefix}-typo_body,
.#{$class-prefix}-typo_body-1 {
    @include getTypography(body-1);
}

.#{$class-prefix}-typo_body-2 {
    @include getTypography(body-2);
}

.#{$class-prefix}-typo_caption {
    @include getTypography(caption);
}

.#{$class-prefix}-typo_secondary {
    @include getTypography(secondary);
}

.#{$class-prefix}-typo_button {
    @include getTypography(button);
}

.#{$class-prefix}-typo_chip {
    @include getTypography(chip);
}

.#{$class-prefix}-typo_tabs {
    @include getTypography(tabs);
}

.#{$class-prefix}-typo--italic {
    font-style: italic;
}

// line height modifiers
.#{$class-prefix}-typo_body.#{$class-prefix}-typo--xhigh,
.#{$class-prefix}-typo_body-1.#{$class-prefix}-typo--xhigh,
.#{$class-prefix}-typo_body-2.#{$class-prefix}-typo--xhigh {
    @include getFontLineheight(body-2, !important);
}

.#{$class-prefix}-typo--shigh {
    line-height: 1 !important;
}

.#{$class-prefix}-typo--xhigh {
    line-height: 1.35 !important;
}
