/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Adaptive and responsive UI
 */

.#{$class-prefix}-adaptive {
    // overflow: auto;
    max-width: getBreakpoints(phone-l);
}

.#{$class-prefix}-adaptive--medium {
    max-width: getBreakpoints(tablet-s);
}

.#{$class-prefix}-adaptive--large {
    max-width: getBreakpoints(phone-l-land);
}

.#{$class-prefix}-adaptive--extralarge {
    max-width: getBreakpoints(tablet-s-land);
}

.#{$class-prefix}-adaptive--verylarge {
    max-width: getBreakpoints(tablet-xl-land);
}

@each $i, $bpval in $map-breakpoints {
    .#{$class-prefix}-adaptive--#{$i} {
        max-width: $bpval;
    }
}
