/**
 *
 * @group Components
 * @subgroup Card
 *
 * @description A card is a piece of paper with unique related data that serves as an entry point to more detailed information.
 *
 * @base .#{$class-prefix}-card Default Bottom sheets
 *
 * @modifier .#{$class-prefix}-card--full Full width card | Card is displayed in full width on devices with a small resolution
 * @modifier .#{$class-prefix}-card--column-1 Specify number of columns | The cards are restricted by percentages
 * @modifier
 *
 * @example <div class="pb-cards"><div class="pb-card [modifierClass]">... TEMPLATE</div></div>
 *
 * @markup
 * <div class="pb-cards">
 *    <div class="pb-card pb-card--productitem ">
 *        ... TEMPLATE
 *    </div>
 * </div>
 *
 */

.#{$class-prefix}-cards {
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 getGrid(1px) getGrid(1px) getGrid(1px);
    width: 100%;
    height: auto;
}

.#{$class-prefix}-cards--square {
    .#{$class-prefix}-card {
        border-radius: 0;
    }
}

.#{$class-prefix}-cards--columns-1 .#{$class-prefix}-card {
    flex-basis: calc(100% - #{getGrid(1px)});
}

@for $i from 2 through 12 {
    .#{$class-prefix}-cards--columns-#{$i} .#{$class-prefix}-card {
        flex-basis: calc(#{percentage(1 / $i)} - #{getGrid(2px)});
    }
}

.#{$class-prefix}-card {
    margin: getGrid(1px);
    padding: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: getRadius(radius, medium);
    background-color: getColor(content);

    @include getDepth(2);
}
