/**
 *
 * @group Components
 * @subgroup Menu
 *
 * @description Menus allow users to take an action by selecting from a list of choices revealed upon opening a temporary, new sheet of material
 *
 * @base .#{$class-prefix}-menu Menu | Menus display a consistent set of menu items, each of which may be enabled or disabled based on the current state of the application.
 *
 * @modifier .#{$class-prefix}-menu--animate Menu animation | Animation to show/hide menu
 * @modifier
 *
 * @example <div class="pb-menu">... TEMPLATE-CODE</div>
 *
 * @markup
 * <div class="pb-menu [modifierClass] styleguide-helper">
 *    <div class="pb-list">
 *        <div class="pb-list_item">
 *            <svg class="pb-icon" viewBox="-4 -4 32 32">
 *                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-apps"></use>
 *            </svg>
 *            <div class="pb-list_header">Kategorien</div>
 *        </div>
 *        <div class="pb-list_item">
 *            <svg class="pb-icon" viewBox="-4 -4 32 32">
 *                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-sort"></use>
 *            </svg>
 *            <div class="pb-list_header">Sortieren</div>
 *        </div>
 *        <div class="pb-list_item">
 *            <svg class="pb-icon" viewBox="-4 -4 32 32">
 *                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-filter_list"></use>
 *            </svg>
 *            <div class="pb-list_header">Filter</div>
 *        </div>
 *    </div>
 * </div>
 *
 */

.#{$class-prefix}-menu {
    /*
  opacity: 0;
  transform: scale(0, 0);
  @include getTransition(0.6s);
  transform-origin: 100% 0;
  */
    opacity: 0;
    position: fixed;
    display: block;
    overflow: auto;
    margin: getGrid(1px) 0;
    //    min-width: getGrid(60px);
    padding: 0;
    width: auto;
    height: auto;
    max-height: calc(100vh - #{getGrid(2px)});
    border-radius: getRadius(radius);
    background-color: getColor(content);
    flex: 1;

    @include getDepth(8);

    white-space: nowrap;
}

.#{$class-prefix}-menu--animate {
    display: block;
    animation: #{$class-prefix}-menu--animation 2s getAnimation(ease) forwards;
    animation-delay: 0.05s;
}

@keyframes #{$class-prefix}-menu--animation {
    0% {
        opacity: 1;
        overflow: hidden;
        max-height: 0;
    }

    99.9% {
        opacity: 1;
        max-height: calc(100vh - #{getGrid(2px)});
    }

    100% {
        opacity: 1;
        overflow: auto;
    }
}
