/**
 *
 * @group Components
 * @subgroup Dialog Full screen
 *
 * @description Full-screen dialogs group a series of tasks (such as creating a calendar entry) before they may be committed or discarded. For closing the fullscreen dialog an icon-button with 'close' or 'back' icon should be provided.
 *
 * @base .#{$class-prefix}-dialogfullscreen Full screen dialog
 *
 * @modifier .#{$class-prefix}-dialog--animate Animation | Show/Hide full screen dialog
 * @modifier .#{$class-prefix}-dialog--fade Fade transition | not used
 * @modifier .#{$class-prefix}-dialog--frombottom Animation from bottom
 * @modifier .#{$class-prefix}-dialog--fromtop Animation from top
 * @modifier .#{$class-prefix}-dialog--fromright Animation from right
 * @modifier .#{$class-prefix}-dialog--fromleft Animation from left
 *
 * @example <div class="pb-dialogfullscreen [modifierClass]">... TEMPLATE-CODE</div>
 *
 */

.#{$class-prefix}-dialogfullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2200;
    display: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100%;
    background-color: getColor(background);
    transform: translate3d(0, 0, 0);
    will-change: transform;

    .#{$class-prefix}-paper {
        height: 100vh;
    }
}

.#{$class-prefix}-dialogfullscreen--animate {
    display: block;
}

// animation fade

.#{$class-prefix}-dialogfullscreen--fade {
    display: block;
    opacity: 0;
    transition: opacity 0.9s getAnimation(ease);
    transform: translate3d(0, 0, 0);
}

.#{$class-prefix}-dialogfullscreen--fade.#{$class-prefix}-dialogfullscreen--animate {
    display: block;
    opacity: 1;
}

// animations from off screen

.#{$class-prefix}-dialogfullscreen--frombottom {
    display: block;
    transition: transform 0.6s getAnimation(ease);
    transform: translate3d(0, 115%, 0);
}

.#{$class-prefix}-dialogfullscreen--fromtop {
    display: block;
    transition: transform 0.6s getAnimation(ease);
    transform: translate3d(0, -115%, 0);
}

.#{$class-prefix}-dialogfullscreen--fromright {
    display: block;
    transition: transform 0.6s getAnimation(ease);
    transform: translate3d(115%, 0, 0);
}

.#{$class-prefix}-dialogfullscreen--fromleft {
    display: block;
    transition: transform 0.6s getAnimation(ease);
    transform: translate3d(-115%, 0, 0);
}

.#{$class-prefix}-dialogfullscreen--fromleft.#{$class-prefix}-dialogfullscreen--animate,
.#{$class-prefix}-dialogfullscreen--fromright.#{$class-prefix}-dialogfullscreen--animate,
.#{$class-prefix}-dialogfullscreen--frombottom.#{$class-prefix}-dialogfullscreen--animate,
.#{$class-prefix}-dialogfullscreen--fromtop.#{$class-prefix}-dialogfullscreen--animate {
    display: block;
    transform: translate3d(0, 0, 0);
}
