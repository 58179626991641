/**
 *
 * @group Components
 * @subgroup Bottom Navigation
 *
 * @description Bottom navigation is a special kind of toolbar that make it easy to explore and switch between top-level views in a single tap
 *
 * @base .#{$class-prefix}-bottomnav Default bottom navigation
 *
 * @modifier .#{$class-prefix}-bottomnav--float Bottom navigation float | Makes the bottom navigation float and not stick to the bottom of the viewport
 * @modifier .#{$class-prefix}-bottomnav--animate Bottom navigation animation | Animation to hide the bottom navigation when user is scrolling down
 * @modifier .#{$class-prefix}-bottomnav--noanimation No animation | Disables animation
 *
 * @example <div class="pb-bottomnav [modifierClass]">... TEMPLATE</div>
 *
 * @markup
 * <nav class="pb-bottomnav pb-theme_dark pb-bottomnav--float styleguide-helper pb-width_50p">
 *    <div class="pb-flex pb-padding_leftright-4">
 *        <div class="pb-flexitem is-center">
 *            <div id="gallery_filter" class="pb-bottomnav_button">
 *                <svg viewBox="0 0 24 24" class="pb-icon pb-icon--bottomnav"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-filter_list"></use></svg>
 *                <label class="pb-bottomnav_label">filter</label>
 *            </div>
 *        </div>
 *        <div class="pb-flexitem is-center">
 *            <div id="gallery_sort" class="pb-bottomnav_button">
 *                <svg viewBox="0 0 24 24" class="pb-icon pb-icon--bottomnav"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-sort"></use></svg>
 *                <label class="pb-bottomnav_label">sort</label>
 *            </div>
 *        </div>
 *    </div>
 * </nav>
 *
 */

.#{$class-prefix}-bottomnav {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: hidden;
    margin: auto;
    max-width: getBreakpoints(phone-l);
    width: 100%;
    height: getGrid(14px);
    text-align: left;
    transition: transform 0.9s getAnimation(ease);
    transform: translate(0, 115%);

    @include getDepth(5);
}

.#{$class-prefix}-bottomnav--float {
    width: calc(100% - #{getGrid(8px)});
    border-radius: getRadius(radius);
    transform: translate(0, #{getGrid(-2px)});

    @include getDepth(6);
}

.#{$class-prefix}-bottomnav--animate {
    transform: translate(0, 0);
}

.#{$class-prefix}-bottomnav_button {
    display: inline-block;
    position: relative;
    padding: getGrid(2px) 0;

    .#{$class-prefix}-icon--bottomnav {
        margin: 0;
        padding: 0;
        min-width: getGrid(6px);
        min-height: getGrid(6px);
        width: getGrid(6px);
        height: getGrid(6px);
    }

    .#{$class-prefix}-bottomnav_label {
        margin: 0;
        padding: 0 getGrid(2px);
        height: auto;

        @include getSingleline();
        @include getTypography(caption);

        line-height: 1.1;
        transition: font-size 0.1s getAnimation(ease);
    }
}

.#{$class-prefix}-bottomnav_button--active {
    .#{$class-prefix}-icon--bottomnav {
        fill: getColor(primary, contrast);
    }

    .#{$class-prefix}-bottomnav_label {
        @include getTypography(body-2);

        color: getColor(primary);
        line-height: 1.1;
    }
}

.#{$class-prefix}-bottomnav_button:active {
    .#{$class-prefix}-icon--bottomnav {
        fill: getColor(primary, contrast);
        background-color: initial;
    }

    .#{$class-prefix}-bottomnav_label {
        color: getColor(primary);
    }
}

// helper for pb-paper
.#{$class-prefix}-has-bottomnav {
    padding-bottom: getGrid(16px) !important;
}

.#{$class-prefix}-icon--bottombadge {
    top: getGrid(-2px) !important;
    right: 30% !important;
}

.#{$class-prefix}-color_secondary.#{$class-prefix}-icon--bottombadge {
    //  fill: getColor(text, secondary) !important;
    //  stroke: getColor(text, secondary) !important;
}

.#{$class-prefix}-bottomnav--noanimation {
    transform: initial;
    transition: initial;
}
