/**
/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Colors and theming functions
 */

// color function
@function getColor($color, $shade:'') {
  $check: $color;
  $check2: $color + '-' + $shade;

  @if map-has-key($map-colors, $check2) {
    @return map-get($map-colors, $check2);
  } @else {
    @if map-has-key($map-colors, $check) {
      @return map-get($map-colors, $check);
    } @else {
      @warn 'Unfortunately, no value could be retrieved from `#{$color}`. '+'Please make sure it is defined in `$md-$map-colors-sizes` map.';
    }
  }
}

// calculate contrasting colors
// http://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
@function getBrightness($color) {
  // constants
  $contrast-red: 241;
  $contrast-green: 691;
  $contrast-blue: 68;
  $color-brightness: $contrast-red + $contrast-green + $contrast-blue; // rgb components
  $red: red($color);
  $green: green($color);
  $blue: blue($color); // Calculate brightness
  $brightness: sqrt((( $red * $red * $contrast-red) + ( $green * $green * $contrast-green) + ( $blue * $blue * $contrast-blue)) / $color-brightness); // return percentage

  @return 100% * $brightness / 255;
}

@function getContrastColor($color, $light, $dark) {
  // constants
  $cutoff: 65%;

  @if getBrightness($color) < $cutoff {
    @return $light;
  } @else {
    @return $dark;
  }
}

// encode color values for svgs href url etc.

@function getEncodedColor($string) {
  @if type-of($string)=='color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');
  }
  $string: '%23'+$string;

  @return $string;
}

/**
 * @module Colors theming
 */

$color-content: #fff; // not needed, but error on build when removed!!!?
// default colors
// primary colors
$color-primary-base: $md-list-blue !default;
$color-secondary-base: $md-list-deep-orange !default;

$color-primary: nth($color-primary-base, 5) !default;
// fake primary light: darken($color-primary, 37%);
$color-primary-light: lighten($color-primary, 37%) !default;
// $color-primary-light: nth($color-primary-base, 1) !default;
// fake primary dark: darken($color-primary,10%);
$color-primary-dark: darken($color-primary, 10%) !default;
$color-primary-contrast: darken($color-primary, 10%) !default;
// $color-primary-dark: nth($color-primary-base, 7) !default;
// fake primary hover
$color-primary-hover: darken($color-primary, 6%) !default;
// $color-primary-hover: nth($color-primary-base, 6) !default;
// fake primary active
$color-primary-active: darken($color-primary, 12%) !default;
// $color-primary-active: nth($color-primary-base, 7) !default;
$color-secondary: nth($color-secondary-base, 13) !default;
$color-secondary-light: lighten($color-secondary, getOpacity(disabled, light) * 100) !default;
$color-secondary-dark: darken($color-secondary, getOpacity(dividers, dark) * 100) !default;

// *** color map
$map-colors: (
  // default backgrounds
        statusbar: $color-primary-dark,
        appbar: $color-primary,
        background: #eee,
        content: #fff, // primary colors
        primary: $color-primary,
        primary-light: $color-primary-light,
        primary-dark: $color-primary-dark,
        primary-hover: $color-primary-hover,
        primary-active: $color-primary-active,
        primary-contrast: getContrastColor($color-content, $color-primary, $color-primary-contrast), // secondary colors
        secondary: $color-secondary,
        secondary-light: $color-secondary-light,
        secondary-dark: $color-secondary-dark, // default text colors on background
        base: getOpacity(color, dark),
        text: getRGBA(text, dark),
        secondarytext: getRGBA(secondarytext, dark),
        disabled: getRGBA(disabled, dark),
        dividers: getRGBA(dividers, dark), // light background colors
        statusbar-light: #e0e0e0,
        appbar-light: #f5f5f5,
        background-light: #fafafa,
        content-light: #fff, // default colors on light background
        base-light: getOpacity(color, light),
        text-light: getRGBA(text, light),
        secondarytext-light: getRGBA(secondarytext, light),
        disabled-light: getRGBA(disabled, light),
        dividers-light: getRGBA(dividers, light), // default contrast colors for primary
        text-primary: getContrastColor($color-primary, getRGBA(text, light), getRGBA(text, dark)),
        secondarytext-primary: getContrastColor($color-primary, getRGBA(secondarytext, light), getRGBA(secondarytext, dark)),
        disabled-primary: getContrastColor($color-primary, getRGBA(disabled, light), getRGBA(disabled, dark)),
        dividers-primary: getContrastColor($color-primary, getRGBA(dividers, light), getRGBA(dividers, dark)), // default contrast colors for secondary
        text-secondary: getContrastColor($color-secondary, getRGBA(text, light), getRGBA(text, dark)),
        secondarytext-secondary: getContrastColor($color-secondary, getRGBA(secondarytext, light), getRGBA(secondarytext, dark)),
        disabled-secondary: getContrastColor($color-secondary, getRGBA(disabled, light), getRGBA(disabled, dark)),
        dividers-secondary: getContrastColor($color-secondary, getRGBA(dividers, light), getRGBA(dividers, dark)), // dark background colors
        statusbar-dark: #000,
        appbar-dark: #212121,
        background-dark: #303030,
        content-dark: #424242, // default colors on dark background
        base-dark: getOpacity(color, dark),
        text-dark: getRGBA(text, dark),
        secondarytext-dark: getRGBA(secondarytext, dark),
        disabled-dark: getRGBA(disabled, dark),
        dividers-dark: getRGBA(dividers, dark), // error - success
        error: #dd2c00,
        success_md: #4caf50,
        success: #43a047, // gradient
        gradient: linear-gradient(to bottom, rgba(0, 0, -1, 0.7) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.1) 70%, transparent 100%),
        gradient-drawer: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.15) 40%, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0) 100%), // greys
        grey-100: nth($md-list-grey, 1),
        grey-200: nth($md-list-grey, 2),
        grey-300: nth($md-list-grey, 3),
        grey-400: nth($md-list-grey, 4),
        grey-500: nth($md-list-grey, 5),
        grey-600: nth($md-list-grey, 6),
        grey-700: nth($md-list-grey, 7),
        grey-800: nth($md-list-grey, 8),
        grey-900: nth($md-list-grey, 9),
        grey-100-light: nth($md-list-grey, 1),
        grey-200-light: nth($md-list-grey, 2),
        grey-300-light: nth($md-list-grey, 3),
        grey-400-light: nth($md-list-grey, 4),
        grey-500-light: nth($md-list-grey, 5),
        grey-600-light: nth($md-list-grey, 6),
        grey-700-light: nth($md-list-grey, 7),
        grey-800-light: nth($md-list-grey, 8),
        grey-900-light: nth($md-list-grey, 9),
        grey-100-dark: nth($md-list-grey, 9),
        grey-200-dark: nth($md-list-grey, 8),
        grey-300-dark: nth($md-list-grey, 7),
        grey-400-dark: nth($md-list-grey, 6),
        grey-500-dark: nth($md-list-grey, 5),
        grey-600-dark: nth($md-list-grey, 4),
        grey-700-dark: nth($md-list-grey, 3),
        grey-800-dark: nth($md-list-grey, 2),
        grey-900-dark: nth($md-list-grey, 1),
);
