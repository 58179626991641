/**
 *
 * @group Components
 * @subgroup Badge
 *
 * @description This component shows a small badge to the top-right of its child(ren)
 *
 * @base .#{$class-prefix}-badge Default badge
 *
 * @modifier .#{$class-prefix}-badge--animation Badge animation | Animation to scale the badge
 * @modifier
 *
 * @example <a href="#url" class="pb-badge" data-badge="#"></a>
 *
 * @markup
 * <a href="#url" class="pb-badge [modifierClass]">1</a>
 *
 */

.#{$class-prefix}-badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    min-width: 1.65em;
    height: 1.65em;
    border-radius: 50%;
    background: getColor(secondary);
    color: getColor(text, secondary);
    text-align: center;
}

.#{$class-prefix}-badge::after {
    content: attr(data-badge);
}

.#{$class-prefix}-badge--square {
    border-radius: getRadius(radius);
    min-width: initial;
    height: auto;
    padding: getGrid(0.5px) getGrid(1px);
}
