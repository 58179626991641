/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Opacities variables based on dark and light theme
 */

/*
 * element                value
 *
 * color-dark             #000
 * base-dark              100%
 * text-dark              87%
 * secondarytext-dark     54%
 * disabled-dark          38%
 * dividers-dark          12%
 *
 * color-light            #fff
 * base-light             100%
 * text-light             100%
 * secondarytext-light    70%
 * disabled-light         30%
 * dividers-light         12%
*/

// opacity map
$map-opacities: (
    // dark
        color-dark: #000,
    base-dark: 100%,
    text-dark: 87%,
    secondarytext-dark: 50%,
    disabled-dark: 30%,
    dividers-dark: 10%,
    // light
        color-light: #fff,
    base-light: 100%,
    text-light: 100%,
    secondarytext-light: 70%,
    disabled-light: 30%,
    dividers-light: 10%,
    light-light: 5%
);

// alpha map
$map-alpha: (
    dark: #000,
    light: #fff,
    900: 80%,
    800: 70%,
    700: 60%,
    600: 50%,
    500: 30%,
    400: 20%,
    300: 10%,
    200: 5%,
    100: 2.5%
);

/**
 * @module Opacities functions based on light and dark theme
 */

// opacity function
@function getOpacity($opacity, $theme: 'dark') {
    $check: $opacity;
    $check2: $opacity + '-' + $theme;

    @if map-has-key($map-opacities, $check2) {
        @return map-get($map-opacities, $check2) / 100%;
    } @else {
        @if map-has-key($map-opacities, $check) {
            @return map-get($map-opacities, $check) / 100%;
        } @else {
            @warn 'Unfortunately, no value could be retrieved from `#{$opacity}`. '+'Please make sure it is defined in `$map-opacities` map.';
        }
    }
}

// opacity function

@function getRGBA($element: 'text', $theme: 'dark') {
    $check: $element + '-' + $theme;
    $color: map-get($map-opacities, 'color-' + $theme);
    $opacity: '100%';

    @if map-has-key($map-opacities, $check) {
        $opacity: map-get($map-opacities, $check) / 100%;
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$opacity}`. '+'Please make sure it is defined in `$map-opacities` map.';
    }

    @return rgba($color, $opacity);
}

// alpha function

@function getAlpha($element: '500', $theme: 'dark') {
    $check: $element;
    $color: map-get($map-alpha, $theme);
    $opacity: '100%';

    @if map-has-key($map-alpha, $check) {
        $opacity: map-get($map-alpha, $check) / 100%;
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$opacity}`. '+'Please make sure it is defined in `$map-opacities` map.';
    }

    @return rgba($color, $opacity);
}
