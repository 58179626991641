/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Decorations
 */

$decoration-radius: 4px !default;
$decoration-radius-small: 2px !default;
$decoration-radius-medium: 6px !default;
$decoration-radius-large: 16px !default;

$map-deco_radius: (
    radius: $decoration-radius,
    radius-small: $decoration-radius-small,
    radius-medium: $decoration-radius-medium,
    radius-large: $decoration-radius-large
);

// radius  function
@function getRadius($type, $value: '') {
    $check: $type;
    $check2: $type + '-' + $value;

    @if map-has-key($map-deco_radius, $check2) {
        @return map-get($map-deco_radius, $check2);
    } @else {
        @if map-has-key($map-deco_radius, $check) {
            @return map-get($map-deco_radius, $check);
        } @else {
            @warn 'Unfortunately, no value could be retrieved from `#{$type}`. '+'Please make sure it is defined in `$map-deco_radius` map.';
        }
    }
}

//  ** Singleline stylings

@mixin getSingleline($important: '') {
    overflow: hidden unquote($important);
    width: 100% unquote($important);
    text-overflow: ellipsis unquote($important);
    white-space: nowrap unquote($important);
}
