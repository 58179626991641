/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Animation
 */

$animation-ease: cubic-bezier(0.19, 1, 0.22, 1) !default;

$map-animation: (
    ease: $animation-ease
);

// base values function
@function getAnimation($type, $value: '') {
    $check: $type;
    $check2: $type + '-' + $value;

    @if map-has-key($map-animation, $check2) {
        @return map-get($map-animation, $check2);
    } @else {
        @if map-has-key($map-animation, $check) {
            @return map-get($map-animation, $check);
        } @else {
            @warn 'Unfortunately, no value could be retrieved from `#{$type}`. '+'Please make sure it is defined in `$map-animation` map.';
        }
    }
}

// mixin for transition
@mixin getTransition($time: 0.3s, $elements: all) {
    transition: $elements $time getAnimation(ease);
}
