/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Depths (shadow and z-index)
 */

// create classes with shadow and z-index
.#{$class-prefix}-raised {
    @include getDepth(2, !important);
}

.#{$class-prefix}-raised_off {
    box-shadow: 0 0 !important;
}

@each $i, $depthval in $map-depths {
    .#{$class-prefix}-raised_#{$i} {
        @include getDepth($i, !important);
    }

    // only shadow, no z-index
    .#{$class-prefix}-shadow_#{$i} {
        box-shadow: unquote(map-get($map-depths, $i)) !important;
    }

    // z-index only
    .#{$class-prefix}-z_#{$i} {
        z-index: $i * 100;
    }
}
