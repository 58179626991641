/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Colors
 */

// text colors
.#{$class-prefix}-color-text_error {
    color: getColor(error);
    fill: getColor(error);
}

.#{$class-prefix}-color-text_success {
    color: getColor(success);
    fill: getColor(success);
}

// gradient
.#{$class-prefix}-gradient {
    background: getColor(gradient);
}

.#{$class-prefix}-gradient-drawer {
    background: getColor(gradient, drawer);
}

// primary
.#{$class-prefix}-color_primary {
    background-color: getColor(primary);
}

.#{$class-prefix}-color_primary-dark {
    background-color: getColor(primary, dark);
}

.#{$class-prefix}-color_primary-light {
    background-color: getColor(primary, light);
}

.#{$class-prefix}-color_primary-hover {
    background-color: getColor(primary, hover);
}

.#{$class-prefix}-color_primary-active {
    background-color: getColor(primary, active);
}

// secondary
.#{$class-prefix}-color_secondary {
    background-color: getColor(secondary);
}

.#{$class-prefix}-color_secondary-dark {
    background-color: getColor(secondary-dark);
}

.#{$class-prefix}-color_secondary-light {
    background-color: getColor(secondary-light);
}

// other
.#{$class-prefix}-color_error {
    background-color: getColor(error);
}

.#{$class-prefix}-color_success {
    background-color: getColor(success);
}

// *** default colors

// background color
.#{$class-prefix}-color_statusbar {
    background-color: getColor(statusbar);
}

.#{$class-prefix}-color_appbar {
    background-color: getColor(appbar);
}

.#{$class-prefix}-color_background {
    background-color: getColor(background);
}

.#{$class-prefix}-color_content {
    background-color: getColor(content);
}

.#{$class-prefix}-color-text {
    color: getColor(text);
    fill: getColor(text);
}

.#{$class-prefix}-color-text_primary {
    color: getColor(primary);
    fill: getColor(primary);
}

.#{$class-prefix}-color-text_secondary {
    color: getColor(secondarytext);
    fill: getColor(secondarytext);
}

.#{$class-prefix}-color-text_disabled {
    color: getColor(disabled);
    fill: getColor(disabled);
}

.#{$class-prefix}-color-text_dividers {
    color: getColor(dividers);
    fill: getColor(dividers);
}

// basic grey shades

.#{$class-prefix}-color_dark {
    background-color: getColor(base, dark);
}

.#{$class-prefix}-color_light {
    background-color: getColor(base, light);
}

// generate grey shades from grey-100 to grey-900
@for $i from 1 through 9 {
    .#{$class-prefix}-color_grey-#{$i * 100} {
        background-color: #{getColor(grey, $i * 100)};
    }
}

// generate alpha values from alpha-100 to alpha-900
@for $i from 1 through 9 {
    .#{$class-prefix}-color_alpha-#{$i * 100} {
        background-color: #{getAlpha($i * 100, dark)};
    }
}
