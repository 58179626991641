/**
 *
 * @group Helpers
 * @subgroup Scrim
 *
 * @description A scrim provides an overlay that will prevent taps from propagating to the controls that it covers.
 *
 * @base .#{$class-prefix}-scrim Scrim
 *
 * @modifier .#{$class-prefix}-scrim--animate Scrim animation | Animation to show/hide the scrim
 * @modifier
 *
 * @example <div class="pb-scrim" id="scrim"></div>
 *
 *
 */

/**
 *
 * @group Helpers
 * @subgroup Scrim
 *
 * @base .#{$class-prefix}-scrim2 Lighter Scrim
 *
 * @modifier .#{$class-prefix}-scrim--animate Scrim animation | Animation to show/hide the scrim
 * @modifier
 *
 * @example <div class="pb-scrim2" id="scrim2"></div>
 *
 * @markup
 * <div class="pb-scrim2" id="scrim2"></div>
 *
 */

.#{$class-prefix}-scrim,
.#{$class-prefix}-scrim2 {
    position: fixed;
    top: 0;
    left: 0;
//    display: none;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    // -webkit-overflow-scrolling: touch;
}

.#{$class-prefix}-scrim {
    //    background-color: rgba(0, 0, 0, 0);
    // hack, because scrim has to be above fullscreen (2200)
    z-index: 700 !important;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.6s getAnimation(ease);
    will-change: opacity;

    //    transform: translate3d(0,0,0);
}

.#{$class-prefix}-scrim--transparent {
    background-color: transparent;
}

.#{$class-prefix}-scrim-dialog,
.#{$class-prefix}-scrim-menu {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.3s getAnimation(ease);
    will-change: opacity;
}

.#{$class-prefix}-scrim2 {
    z-index: 700;
}

.#{$class-prefix}-scrim--animate,
.#{$class-prefix}-scrim2--animate {
    display: block;
}

.#{$class-prefix}-scrim--animate {
    opacity: getOpacity(secondarytext, dark);
}
