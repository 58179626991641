/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Theme light classes
 */

.#{$class-prefix}-color_light-statusbar {
    background-color: getColor(statusbar, light) !important;
}

.#{$class-prefix}-color_light-appbar {
    background-color: getColor(appbar, light) !important;
}

.#{$class-prefix}-color_light-background {
    background-color: getColor(background, light) !important;
}

.#{$class-prefix}-color_light-content {
    background-color: getColor(content, light) !important;
}

.#{$class-prefix}-color-text_dark {
    color: getColor(text, dark) !important;
}

.#{$class-prefix}-color-text_dark-secondary {
    color: getColor(secondarytext, dark) !important;
}

.#{$class-prefix}-color-text_dark-disabled {
    color: getColor(disabled, dark) !important;
}

.#{$class-prefix}-color-text_dark-dividers {
    color: getColor(dividers, dark) !important;
}
