/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Theming on elements
 */
@import 'theming-elements-scheme';
@import 'theming-elements-text-scheme';

.pb-theme_test {
    color: red;
}

@include tmSchemeText(text-dark, light, dark);
@include tmSchemeText(text-light, dark, light);
@include tmScheme(light, light, dark);
@include tmScheme(dark, dark, light);
@include tmScheme(primary, dark, light, getColor(primary));

// *** transparent theme
.#{$class-prefix}-header.#{$class-prefix}-theme_transparent,
.#{$class-prefix}-theme_transparent {
    background: getColor(gradient) !important;
    background-color: transparent !important;
    box-shadow: none !important;

    .#{$class-prefix}-toolbar .logo,
    .#{$class-prefix}-appbar_title,
    .#{$class-prefix}-toolbar_title {
        opacity: 0;
    }

    .#{$class-prefix}-badge {
        box-shadow: 0 0 0 2px transparent;
    }

    .#{$class-prefix}-icon {
        fill: getColor(text, light);
    }
}
