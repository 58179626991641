/**
 *
 * @group Components
 * @subgroup Buttons
 *
 * @description A button clearly communicates what action will occur when the user touches it. It consists of text, an image, or both, designed in accordance with your app’s color theme.
 *
 * @base .#{$class-prefix}-button Default button
 *
 * @modifier .#{$class-prefix}-button--text Text button
 * @modifier .#{$class-prefix}-button--outline Outline button
 * @modifier .#{$class-prefix}-button--raised Raised button
 * @modifier .#{$class-prefix}-button--disabled Disabled button
 * @modifier .#{$class-prefix}-button--active Active button
 * @modifier .#{$class-prefix}-button--iconright Align icon to the right
 *
 * @state :hover Hovered button
 * @state :active Active clicked button
 * @state :disabled Disabled button
 *
 * @example <button class="tm-button [modifierClass]">[description]</button>
 *
 * @markup
 * <button class="tm-button [modifierClass]">Button</button>
 *
 */

// *** Default button
.#{$class-prefix}-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
    margin: getGrid(1.5px) getGrid(3px);
    padding: 0 getGrid(2px);
    width: auto;
    height: getGrid(9px);
    max-width: calc(100% - #{getGrid(8px)});
    border: none;
    border-radius: getRadius(radius);
    cursor: pointer;
    user-select: none;
    transition: all 0.6s getAnimation(ease);
    background-color: getColor(primary);
    color: getColor(text, primary);

    .#{$class-prefix}-button_label {
        order: 2;
        display: inline-block;
        margin: 0 getGrid(2px);
        padding: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;

        @include getTypography(button);

        letter-spacing: 0;
        line-height: 0;
    }

    .#{$class-prefix}-icon {
        order: 1;
        min-width: getGrid(9px);
        min-height: getGrid(9px);
        width: getGrid(9px);
        height: getGrid(9px);
        margin: 0;
        padding: 0;
        border-radius: 0;
        // display: inline-block;
        fill: getColor(text, primary);

        &:active {
            background-color: initial;
        }
    }
}

.#{$class-prefix}-button--iconright {
    .#{$class-prefix}-icon {
        order: 3;
    }
}

.#{$class-prefix}-button:hover,
.#{$class-prefix}-button--hover {
    background-color: getColor(primary, hover);
}

// active
.#{$class-prefix}-button:active,
.#{$class-prefix}-button--active {
    background-color: getColor(primary, active);
}

// disabled button
.#{$class-prefix}-button:disabled,
.#{$class-prefix}-button.#{$class-prefix}-button--disabled,
.#{$class-prefix}-button.#{$class-prefix}-button--disabled:active,
.#{$class-prefix}-button.#{$class-prefix}-button--active.#{$class-prefix}-button--disabled {
    background-color: getColor(dividers);
    color: getColor(disabled);
    opacity: 0.75;
    box-shadow: none;
    cursor: default;
    pointer-events: none;

    &:active {
        background-color: getColor(dividers);
        color: getColor(disabled);
    }

    .#{$class-prefix}-icon {
        fill: getColor(disabled);
        opacity: 0.75;
        cursor: default;
    }
}

// *** Button centered
.#{$class-prefix}-button--center {
    justify-content: center;

    .#{$class-prefix}-button_label {
        flex: initial;
    }
}

// *** Button raised
.#{$class-prefix}-button--raised {
    @include getDepth(2);
}

.#{$class-prefix}-button--raised:active,
.#{$class-prefix}-button--active.#{$class-prefix}-button--raised {
    @include getDepth(8);
}

.#{$class-prefix}-button--raised.#{$class-prefix}-button--disabled,
.#{$class-prefix}-button--raised.#{$class-prefix}-button--disabled:active,
.#{$class-prefix}-button--raised.#{$class-prefix}-button--active.#{$class-prefix}-button--disabled {
    @include getDepth(2);

    &:active {
        @include getDepth(8);
    }
}

// *** Text button
.#{$class-prefix}-button--text {
    margin-right: getGrid(2px);
    margin-left: getGrid(2px);
    padding-right: getGrid(2px);
    padding-left: getGrid(2px);
    padding-bottom: 0;
    background-color: transparent;
    color: getColor(primary, contrast);
    border: none;

    .#{$class-prefix}-icon {
        fill: getColor(primary, contrast);
    }
}

// hovered button
.#{$class-prefix}-button--text:hover,
.#{$class-prefix}-button--hover.#{$class-prefix}-button--text {
    background-color: transparent;
}

.#{$class-prefix}-button.#{$class-prefix}-button--text:active,
.#{$class-prefix}-button.#{$class-prefix}-button--text.#{$class-prefix}-button--active {
    background-color: getColor(dividers);
}

// *** Outline button
.#{$class-prefix}-button--outline {
    border: 1px solid getColor(disabled);
    line-height: getGrid(8.5px);
    padding-bottom: 0;
    background-color: transparent;
    color: getColor(primary, contrast);

    .#{$class-prefix}-icon {
        position: relative;
        top: -2px;
        fill: getColor(primary, contrast);
    }
}

.#{$class-prefix}-button.#{$class-prefix}-button--outline:hover,
.#{$class-prefix}-button.#{$class-prefix}-button--hover.#{$class-prefix}-button--outline {
    background-color: transparent;
}

.#{$class-prefix}-button--outline:active,
.#{$class-prefix}-button--outline.#{$class-prefix}-button--active {
    border-color: rgba(0, 0, 0, 0);
}

.#{$class-prefix}-button.#{$class-prefix}-button--outline:active,
.#{$class-prefix}-button.#{$class-prefix}-button--outline.#{$class-prefix}-button--active {
    background-color: getColor(dividers);
}

.#{$class-prefix}-button.#{$class-prefix}-button--outline.#{$class-prefix}-button--disabled,
.#{$class-prefix}-button.#{$class-prefix}-button--outline.#{$class-prefix}-button--disabled:active,
.#{$class-prefix}-button.#{$class-prefix}-button--outline.#{$class-prefix}-button--disabled.#{$class-prefix}-button--active {
    background-color: transparent;
    opacity: 0.5;
}

.#{$class-prefix}-button--text.#{$class-prefix}-button--disabled,
.#{$class-prefix}-button--text.#{$class-prefix}-button--disabled:active,
.#{$class-prefix}-button--text.#{$class-prefix}-button--active.#{$class-prefix}-button--disabled {
    color: getColor(disabled);
    background-color: transparent;
    opacity: 0.5;

    &:active {
        background-color: transparent;
    }
}

.#{$class-prefix}-button--icon {
    padding: 0;
    color: transparent;

    .#{$class-prefix}-button_label {
        font-size: 0;
        margin: 0;
    }

    .#{$class-prefix}-icon {
        margin: 0;
        padding: 2px;
        border-radius: 0;
    }
}

.#{$class-prefix}-button--big {
    height: getGrid(12px);
    line-height: getGrid(12px);

    .#{$class-prefix}-icon {
        margin-top: 0;
    }
}

.#{$class-prefix}-button.#{$class-prefix}-button--iconright.#{$class-prefix}-button--big {
    .#{$class-prefix}-icon {
        margin: 0 !important;
    }
}
