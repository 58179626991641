/**
 *
 * @group Components
 * @subgroup Progress & activity
 *
 * @description Progress and activity indicators are visual indications of an app loading content.
 *
 *
 * @modifier .#{$class-prefix}-progress_determinate Linear determinate | When indicators are determinate they indicate how long an operation will take when the percentage complete is detectable.
 * @modifier .#{$class-prefix}-progress_indeterminate Linear indeterminate | When indicators are indeterminate they request that the user wait while something finishes when it’s not necessary to indicate how long it will take.
 *
 * @example <div class="pb-progress"><div class="[modifierClass]"></div></div>
 *
 * @markup
 * <div class="pb-progress pb-margin_4 pb-width-50p">
 *    <div class="[modifierClass]"></div>
 * </div>
 *
 */

$progress-color: getColor(secondary);
$progress-color-light: getColor(secondary, light);

.#{$class-prefix}-progress {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    width: 100%;
    height: 4px;
    background-color: $progress-color-light;
    background-clip: padding-box;
}

.#{$class-prefix}-progress--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000; // *** float above other gui-elements
}

.#{$class-prefix}-progress_determinate {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    background-color: $progress-color;
    transition: width 0.3s linear;
}

.#{$class-prefix}-progress_indeterminate {
    background-color: $progress-color;
}

.#{$class-prefix}-progress_indeterminate::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: inherit;
    content: '';
    animation: pb-indeterminate-animation 2.1s
        cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    will-change: left, right;
}

.#{$class-prefix}-progress_indeterminate::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: inherit;
    content: '';
    animation: pb-indeterminate-short-animation 2.1s
        cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
    will-change: left, right;
}

@keyframes pb-indeterminate-animation {
    0% {
        left: -35%;
        right: 100%;
    }

    60% {
        left: 100%;
        right: -90%;
    }

    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes pb-indeterminate-short-animation {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
}

// loader scrim
.#{$class-prefix}-loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    flex: 1;
    justify-content: center;
    align-items: center;
}
