/**
 *
 * @group Components
 * @subgroup Tabs
 *
 * @description Tabs make it easy to explore and switch between different views enabling content organization at a high level, such as switching between views, data sets, or functional aspects of an app.
 *
 * @base .#{$class-prefix}-tabs Collection of tabs
 *
 * @example <nav class="pb-tabs">... TEMPLATE</button>
 *
 * @markup
 * <nav class="pb-tabs pb-color_grey-800" id="js-detailsTabs_tabs">
 *    <button data-tab="1" class="pb-tabs_tab pb-tabs_tab--active">TAB 1</button>
 *    <button data-tab="2" class="pb-tabs_tab">TAB 2</button>
 * </nav>
 *
 */

.#{$class-prefix}-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: getGrid(12px);
    white-space: nowrap;

    // disable scrollbar when more tabs then available space
    &::-webkit-scrollbar {
        display: none;
    }
}

/**
 *
 * @group Components
 * @subgroup Tab
 *
 * @description A single tab
 *
 * @base .#{$class-prefix}-tabs_tab Default tab
 *
 * @modifier .#{$class-prefix}-tab--active Active tab
 * @modifier
 *
 * @state :active Active tab
 * @state
 *
 * @example <button class="pb-tabs_tab">... CONTENT</button>
 *
 * @markup

 * <nav class="pb-tabs pb-color_grey-800">
 *    <button data-tab="1" class="pb-tabs_tab [modifierClass]">TAB 1</button>
 * </nav>
 *
 */

.#{$class-prefix}-tabs_tab {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0 getGrid(3px);
    width: auto;
    height: calc(100% - #{getGrid(0.25px)});
    outline: none;
    color: getColor(secondarytext);
    cursor: pointer;

    @include getTypography(tabs);
    @include getTransition(0.6s);

    .#{$class-prefix}-icon {
        display: inline-block;

        &:active {
            background-color: transparent;
        }
    }
}

.#{$class-prefix}-tabs_tab:active {
    background-color: getColor(disabled, light);
    color: getColor(text);
}

.#{$class-prefix}-tabs_tab--active {
    border-bottom: getGrid(0.5px) solid getColor(secondary);
    color: getColor(text);
}

.#{$class-prefix}-tabscontent {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.9s getAnimation(ease);
    transform: translate(0, 0);
}

.#{$class-prefix}-tabscontent--tab1 {
    transform: translate(0, 0);
}

.#{$class-prefix}-tabscontent--tab2 {
    transform: translate(-100%, 0);
}

.#{$class-prefix}-tabscontent--tab3 {
    transform: translate(-200%, 0);
}

.#{$class-prefix}-tabscontent--tab4 {
    transform: translate(-300%, 0);
}

.#{$class-prefix}-tabscontent_tab {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    overflow: auto;
}

.#{$class-prefix}-tabscontent_tab--1 {
    left: 0;
}

.#{$class-prefix}-tabscontent_tab--2 {
    left: 100%;
}

.#{$class-prefix}-tabscontent_tab--3 {
    left: 200%;
}

.#{$class-prefix}-tabscontent_tab--4 {
    left: 300%;
}
