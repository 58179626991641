/**
 *
 * @group Components
 * @subgroup Input and controls
 *
 * @description Sliders are ideal components for adjusting settings that reflect intensity levels, such as volume, brightness, or color saturation.
 *
 * @base .#{$class-prefix}-rangeslider Sliders | Sliders let users select a value from a continuous or discrete range of values by moving the slider thumb. The smallest value is to the left, the largest to the right. Sliders can have icons to the left and right of the bar that reflect the value intensity.
 *
 * @example <input type="range" class="pb-rangeslider" value="25">
 *
 * @markup
 * <input type="range" class="pb-rangeslider" value="25">
 *
 */

input[type=range].#{$class-prefix}-rangeslider {
  margin: 0;
  padding: 0;
  width: 100%;
  height: getGrid(12px);
  outline: none;
  -webkit-appearance: none;
}

input[type=range].#{$class-prefix}-rangeslider:focus {
  outline: none;
}

input[type=range].#{$class-prefix}-rangeslider::-webkit-slider-runnable-track {
  width: 100%;
  height: getGrid(0.5px);
  border: none;
  border-radius: 0;
  background-color: getColor(dividers);
  transition: background-color 0.6s getAnimation(ease);
  box-shadow: none;
  cursor: pointer;
  // animate: 0.2s;
}

input[type=range].#{$class-prefix}-rangeslider:active::-webkit-slider-runnable-track {
  background-color: getColor(disabled);
}

.#{$class-prefix}-theme_transparent,
.#{$class-prefix}-theme_dark {
  input[type=range].#{$class-prefix}-rangeslider::-webkit-slider-runnable-track {
    background-color: getColor(dividers, light);
  }

  input[type=range].#{$class-prefix}-rangeslider:active::-webkit-slider-runnable-track {
    background-color: getColor(disabled, light);
  }
}

input[type=range].#{$class-prefix}-rangeslider::-webkit-slider-thumb {
  position: relative;
  transform: scale(1) translateY(-50%);
  transform-origin: center;
  width: getGrid(4px);
  height: getGrid(4px);
  border: none;
  border-radius: 50%;
  background-color: getColor(secondary);
  box-shadow: none;
  cursor: pointer;
  transition: transform 0.6s getAnimation(ease);
  -webkit-appearance: none;
}

input[type=range].#{$class-prefix}-rangeslider:active::-webkit-slider-thumb {
  transform: scale(1.5) translateY(-33%);
}


/*  alternative version for fixed width

input[type=range].#{$class-prefix}-rangeslider {
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  margin: 10px 50px;
  background: linear-gradient(to right, getColor(dividers) 0%, getColor(dividers) 100%);
  background-size: 100% 2px;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  outline: none;
  zoom: 1;
  display: inline-block;
  margin: auto;
  margin-bottom: 30px;
}
input[type=range].#{$class-prefix}-rangeslider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border:none;
  box-shadow: none;
  background: getColor(secondary);
  position: relative;
  z-index: 3;
}
input[type=range].#{$class-prefix}-rangeslider::-webkit-slider-thumb:after {
  content: ' ';
  width: 100vw;
  height: 2px;
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 7px;
  background: getColor(secondary);
}
*/
