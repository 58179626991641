/**
 *
 * @group Components
 * @subgroup Header
 *
 * @description Header encloses elements above the content. Usually they include an tool- or appbar. For further elements you can adjust the height of the header with the modifier medium and large.
 *
 * @base .#{$class-prefix}-header Header
 *
 * @modifier .#{$class-prefix}-header--medium Medium header
 * @modifier .#{$class-prefix}-header--mediumlarge Medium large header
 * @modifier .#{$class-prefix}-header--large Large header
 *
 * @example <div class="pb-header [modifierClass]">[description]</div>
 *
 * @markup
 * <div class="pb-header [modifierClass] styleguide-helper pb-width_100p"
 *    <nav class="pb-toolbar pb-theme_primary">
 *        <svg class="pb-icon" viewBox="-4 -4 32 32">
 *            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-menu"></use>
 *        </svg>
 *        <h1 class="pb-flexitem pb-appbar_title">App bar</h1>
 *     </nav>
 * </div>
 *
 */

.#{$class-prefix}-header {
    z-index: 400;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    opacity: 1;
    transition: transform 0.6s getAnimation(ease), box-shadow 0.1s linear;
    transform: translate(0, 0);
    user-select: none;
    background-color: getColor(appbar);
    color: getColor(text, primary);

    .#{$class-prefix}-icon {
        fill: getColor(secondarytext, primary);
    }
}

.#{$class-prefix}-header--medium {
    height: getGrid(26px);
}

.#{$class-prefix}-header--mediumlarge {
    height: getGrid(28px);
}

.#{$class-prefix}-header--large {
    height: getGrid(39px);
}

.#{$class-prefix}-has-header,
.#{$class-prefix}-has-header-small {
    padding-top: getGrid(14px) !important;
    //  height: calc(100% - #{ getGrid(14px) }) !important;
    //  height: 100% !important;
    //   overflow: auto;
}

.#{$class-prefix}-has-header-medium {
    padding-top: getGrid(26px) !important;
    //  height: calc(100% - #{ getGrid(26px) }) !important;
    //    overflow: auto;
}

.#{$class-prefix}-has-header-mediumlarge {
    padding-top: getGrid(28px) !important;
    //  height: calc(100% - #{ getGrid(28px) }) !important;
    //    overflow: auto;
}

.#{$class-prefix}-has-header-large {
    padding-top: getGrid(39px) !important;
    //  height: 70vh !important;
    //  height: calc(100% - #{ getGrid(39px) }) !important;
    //    overflow: auto;
}

.#{$class-prefix}-header--animate {
    transform: translate(0, -115%);
}
