/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Grid and flex helper
 */

/*
 * Status bar: 24dp
 * Toolbar: 56dp
 * Title and list items: 72dp
 * Subtitle: 48dp
 * Space between content areas: 8dp
 */

$grid-baseunit: 4 !default;
$grid-baseblockunit: 56 !default;

/**
 * @module Adaptive breakpoints
 */

/*
 * 0
 * 360
 * 400
 * 480
 * 600
 * 720
 * 840
 * 960
 * 960
 * 1024
 * 1280
 * 1440
 * 1600
 * 1920
 */

// breakpoints map
$map-breakpoints: (
    phone-xs: 0,
    phone-s: 320px,
    phone-m: 360px,
    phone-l: 480px,
    tablet-s: 600px,
    phone-l-land: 720px,
    tablet-l: 840px,
    tablet-s-land: 960px,
    tablet-l-land: 960px,
    tablet-xl-land: 1280px,
    desktop-m: 1440px,
    desktop-l: 1920px
);

/**
 * @module Adaptive and responsive UI functions
 */

@function getBreakpoints($breakpoint) {
    @if map-has-key($map-breakpoints, $breakpoint) {
        @return map-get($map-breakpoints, $breakpoint);
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '+'Please make sure it is defined in `$map-breakpoints` map.';
    }
}

/**
 * @module Grid and flex helper functions
 */

@function getGrid($size) {
    @return $size * $grid-baseunit;
}
