/**
 *
 * @group Components
 * @subgroup Bottom Sheet
 *
 * @description Bottom sheets slide up from the bottom edge of the screen to reveal additional content.
 *
 * @base .#{$class-prefix}-bottomsheet Default Bottom sheets
 *
 * @modifier .#{$class-prefix}-bottomsheet--animate Bottom sheets animation | Animation to display the bottom sheet
 * @modifier
 *
 * @example <div class="pb-bottomsheet">... TEMPLATE</div>
 *
 * @markup
 * <div style="z-index: 2200" class="pb-bottomsheet pb-color_content pb-bottomsheet--animate styleguide-helper">
 *    <aside>
 *        <div class="productcolors_header">
 *            <div class="pb-subheader pb-flexitem">colors_choose</div>
 *            <button type="button">
 *                <svg viewBox="-4 -4 32 32" class="pb-icon a-hint">
 *                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-photo"></use>
 *                </svg>
 *            </button>
 *        </div>
 *        <div class="productcolors">
 *            <button class="productcolors_item a-revealblock">
 *                <div class="productcolors_color product-color_rot"></div>
 *                <div class="productcolors_label">rot</div>
 *              </button>
 *              <button class="productcolors_item a-revealblock">
 *                <div class="productcolors_color product-color_braun"></div>
 *                <div class="productcolors_label">braun</div>
 *              </button>
 *              <button class="productcolors_item a-revealblock">
 *                  <div class="productcolors_color product-color_schwarz"></div>
 *                  <div class="productcolors_label">schwarz</div>
 *              </button>
 *        </div>
 *    </aside>
 * </div>
 *
 */

.#{$class-prefix}-bottomsheet {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2300;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    margin: auto;
    max-width: getBreakpoints(phone-l);
    max-height: calc(100% - 56px);
    width: 100%;
    height: auto;
    text-align: left;
    transition: transform 0.6s getAnimation(ease);
    transform: translate(0, calc(100% + 8px));
    background-color: getColor(content);

    @extend .#{$class-prefix}-raised_16;
}

.#{$class-prefix}-bottomsheet--animate {
    transform: translate(0, 0);
}
