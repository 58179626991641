/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Grid and flex helper
 */

// element is x-times larger than the others
// basic 12 columns grid
@for $i from 1 through 10 {
    .#{$class-prefix}-grid_x#{$i} {
        flex-grow: $i;
    }
}

// basic 12 columns grid
@for $i from 1 through 12 {
    .#{$class-prefix}-grid_#{$i} {
        flex: none !important;
        width: percentage($i/12) !important;
        float: left;
    }
}

// basic 12 columns grid, substracts margin of cards

.#{$class-prefix}-grid_row {
    overflow-x: hidden;
    overflow-y: auto;
}

@for $i from 1 through 12 {
    .#{$class-prefix}-grid_row .#{$class-prefix}-grid_#{$i} {
        flex: none;
        width: calc(#{percentage($i/12)} - #{getGrid(2px)}) !important;
        min-width: initial;
    }
}

// flex helper
.#{$class-prefix}-flex {
    display: flex !important;
}

.#{$class-prefix}-flexitem {
    flex: 1 !important;
    //fixes flex behaviour of the min-width of that is initially set to auto, which equates to the width of the wider element. The flex item is literally not allowed to be any narrower than that element.
    //this instructs flexbox that it’s OK for this item to be narrower than the content within it.
    min-width: 0;
}

// pb-block
.#{$class-prefix}-no-flex {
    display: initial !important;
}

// pb-noflexitem
.#{$class-prefix}-no-flexitem {
    flex: none !important;
}

.#{$class-prefix}-flex--wrap {
    flex-wrap: wrap !important;
}

.#{$class-prefix}-flex--nowrap {
    flex-wrap: nowrap !important;
}

.#{$class-prefix}-flex--column {
    flex-direction: column !important;
}

.#{$class-prefix}-flex--row {
    flex-direction: row !important;
}

.#{$class-prefix}-flex--out {
    justify-content: space-between !important;
}

.#{$class-prefix}-flex--in {
    justify-content: space-around !important;
}

.#{$class-prefix}-flex--even {
    justify-content: space-evenly !important;
}

.#{$class-prefix}-flex--center {
    justify-content: center !important;
}

.#{$class-prefix}-flex--middle {
    align-items: center !important;
}

.#{$class-prefix}-flexitem--center {
    align-self: center;
}

.#{$class-prefix}-flexitem--start {
    align-self: flex-start;
}

.#{$class-prefix}-flexitem--end {
    align-self: flex-end;
}

.#{$class-prefix}-flex--left {
    justify-content: flex-start !important;
}

.#{$class-prefix}-flex--right {
    justify-content: flex-end !important;
}

//  width and height helper
@for $i from 1 through 18 {
    .#{$class-prefix}-width_#{$i} {
        width: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-height_#{$i} {
        height: #{$i * $grid-baseunit}px !important;
    }
}

// by percent, 5% increments
@for $i from 1 through 20 {
    .#{$class-prefix}-width_#{$i * 5}p {
        width: #{$i * 5}% !important;
        min-width: #{$i * 5}% !important;
        max-width: #{$i * 5}% !important;
    }
}

// padding helper

.#{$class-prefix}-padding_off {
    padding: 0 !important;
}

@for $i from 0 through 20 {
    .#{$class-prefix}-padding_#{$i} {
        padding: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-padding_left-#{$i} {
        padding-left: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-padding_right-#{$i} {
        padding-right: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-padding_top-#{$i} {
        padding-top: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-padding_bottom-#{$i} {
        padding-bottom: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-padding_topbottom-#{$i} {
        padding-top: #{$i * $grid-baseunit}px !important;
        padding-bottom: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-padding_leftright-#{$i} {
        padding-left: #{$i * $grid-baseunit}px !important;
        padding-right: #{$i * $grid-baseunit}px !important;
    }
}

.#{$class-prefix}-margin_off {
    margin: 0 !important;
}

.#{$class-prefix}-margin_auto {
    margin: auto !important;
}

// margin helper
@for $i from 0 through 20 {
    .#{$class-prefix}-margin_#{$i} {
        margin: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-margin_left-#{$i} {
        margin-left: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-margin_right-#{$i} {
        margin-right: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-margin_top-#{$i} {
        margin-top: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-margin_bottom-#{$i} {
        margin-bottom: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-margin_topbottom-#{$i} {
        margin-top: #{$i * $grid-baseunit}px !important;
        margin-bottom: #{$i * $grid-baseunit}px !important;
    }

    .#{$class-prefix}-margin_leftright-#{$i} {
        margin-left: #{$i * $grid-baseunit}px !important;
        margin-right: #{$i * $grid-baseunit}px !important;
    }
}
