/**
 *
 * @group Components
 * @subgroup Refresh
 *
 * @description Component not used
 *
 * @base .#{$class-prefix}-refresh
 *
 *
 */

.#{$class-prefix}-refresh {
    margin: 0;
    padding: 1px 0 0;
    border-radius: 50%;
    line-height: getGrid(10px);
    width: getGrid(10px);
    height: getGrid(10px);
    background-color: getOpacity(color, light);
    color: getColor(secondary);

    @include getDepth(3);

    &:active {
        background-color: getOpacity(color, light);

        @include getDepth(3);

        transform: translate(0, 0);
    }

    .#{$class-prefix}-icon {
        min-width: getGrid(10px);
        width: getGrid(10px);
        height: getGrid(10px);
        min-height: getGrid(10px);
        fill: getColor(secondary) !important;
    }
}

.#{$class-prefix}-refresh--animate {
    .#{$class-prefix}-icon {
        animation: md-animation--spin 1s linear infinite;
    }
}

@keyframes md-animation--spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
