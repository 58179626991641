// ==========================================================================
//
// Name:           UI Color Palette
// Description:    The color palette of material design.
// Version:        2.1.0
//
// Author:         Denis Malinochkin
// Git:            https://github.com/mrmlnc/material-color
//
// twitter:        @mrmlnc
//
// ==========================================================================

// Palettes
// --------------------------------------------------------------------------

// Red
$md-list-red: #ffebee, #ffcdd2, #ef9a9a, #e57373, #ef5350,
  #f44336, #e53935, #d32f2f, #c62828, #b71c1c,
  #ff8a80, #ff5252, #ff1744, #d50000;

// Pink
$md-list-pink: #fce4ec, #f8bbd0, #f48fb1, #f06292, #ec407a,
  #e91e63, #d81b60, #c2185b, #ad1457, #880e4f,
  #ff80ab, #ff4081, #f50057, #c51162;

// Purple
$md-list-purple: #f3e5f5, #e1bee7, #ce93d8, #ba68c8, #ab47bc,
  #9c27b0, #8e24aa, #7b1fa2, #6a1b9a, #4a148c,
  #ea80fc, #e040fb, #d500f9, #a0f;

// Deep Purple
$md-list-deep-purple: #ede7f6, #d1c4e9, #b39ddb, #9575cd, #7e57c2,
  #673ab7, #5e35b1, #512da8, #4527a0, #311b92,
  #b388ff, #7c4dff, #651fff, #6200ea;

// Indigo
$md-list-indigo: #e8eaf6, #c5cae9, #9fa8da, #7986cb, #5c6bc0,
  #3f51b5, #3949ab, #303f9f, #283593, #1a237e,
  #8c9eff, #536dfe, #3d5afe, #304ffe;

// Blue
$md-list-blue: #e3f2fd, #bbdefb, #90caf9, #64b5f6, #42a5f5,
  #2196f3, #1e88e5, #1976d2, #1565c0, #0d47a1,
  #82b1ff, #448aff, #2979ff, #2962ff;

// Light Blue
$md-list-light-blue: #e1f5fe, #b3e5fc, #81d4fa, #4fc3f7, #29b6f6,
  #03a9f4, #039be5, #0288d1, #0277bd, #01579b,
  #80d8ff, #40c4ff, #00b0ff, #0091ea;

// Cyan
$md-list-cyan: #e0f7fa, #b2ebf2, #80deea, #4dd0e1, #26c6da,
  #00bcd4, #00acc1, #0097a7, #00838f, #006064,
  #84ffff, #18ffff, #00e5ff, #00b8d4;

// Teal
$md-list-teal: #e0f2f1, #b2dfdb, #80cbc4, #4db6ac, #26a69a,
  #009688, #00897b, #00796b, #00695c, #004d40,
  #a7ffeb, #64ffda, #1de9b6, #00bfa5;

// Green
$md-list-green: #e8f5e9, #c8e6c9, #a5d6a7, #81c784, #66bb6a,
  #4caf50, #43a047, #388e3c, #2e7d32, #1b5e20,
  #b9f6ca, #69f0ae, #00e676, #00c853;

// Light Green
$md-list-light-green: #f1f8e9, #dcedc8, #c5e1a5, #aed581, #9ccc65,
  #8bc34a, #7cb342, #689f38, #558b2f, #33691e,
  #ccff90, #b2ff59, #76ff03, #64dd17;

// Lime
$md-list-lime: #f9fbe7, #f0f4c3, #e6ee9c, #dce775, #d4e157,
  #cddc39, #c0ca33, #afb42b, #9e9d24, #827717,
  #f4ff81, #eeff41, #c6ff00, #aeea00;

// Yellow
$md-list-yellow: #fffde7, #fff9c4, #fff59d, #fff176, #ffee58,
  #ffeb3b, #fdd835, #fbc02d, #f9a825, #f57f17,
  #ffff8d, #ff0, #ffea00, #ffd600;

// Amber
$md-list-amber: #fff8e1, #ffecb3, #ffe082, #ffd54f, #ffca28,
  #ffc107, #ffb300, #ffa000, #ff8f00, #ff6f00,
  #ffe57f, #ffd740, #ffc400, #ffab00;

// Orange
$md-list-orange: #fff3e0, #ffe0b2, #ffcc80, #ffb74d, #ffa726,
  #ff9800, #fb8c00, #f57c00, #ef6c00, #e65100,
  #ffd180, #ffab40, #ff9100, #ff6d00;

// Deep Orange
$md-list-deep-orange: #fbe9e7, #ffccbc, #ffab91, #ff8a65, #ff7043,
  #ff5722, #f4511e, #e64a19, #d84315, #bf360c,
  #ff9e80, #ff6e40, #ff3d00, #dd2c00;

// Brown
$md-list-brown: #efebe9, #d7ccc8, #bcaaa4, #a1887f, #8d6e63,
  #795548, #6d4c41, #5d4037, #4e342e, #3e2723;

// Grey
$md-list-grey: #fafafa, #f5f5f5, #eee, #e0e0e0, #bdbdbd,
  #9e9e9e, #757575, #616161, #424242, #212121;

// Blue Grey
$md-list-blue-grey: #eceff1, #cfd8dc, #b0bec5, #90a4ae, #78909c,
  #607d8b, #546e7a, #455a64, #37474f, #263238;

// Definitions
// --------------------------------------------------------------------------

// Red
$md-clr-red: nth($md-list-red, 6);

$md-clr-red-50: nth($md-list-red, 1);
$md-clr-red-100: nth($md-list-red, 2);
$md-clr-red-200: nth($md-list-red, 3);
$md-clr-red-300: nth($md-list-red, 4);
$md-clr-red-400: nth($md-list-red, 5);
$md-clr-red-500: nth($md-list-red, 6);
$md-clr-red-600: nth($md-list-red, 7);
$md-clr-red-700: nth($md-list-red, 8);
$md-clr-red-800: nth($md-list-red, 9);
$md-clr-red-900: nth($md-list-red, 10);
$md-clr-red-A100: nth($md-list-red, 11);
$md-clr-red-A200: nth($md-list-red, 12);
$md-clr-red-A400: nth($md-list-red, 13);
$md-clr-red-A700: nth($md-list-red, 14);

// Pink
$md-clr-pink: nth($md-list-pink, 6);

$md-clr-pink-50: nth($md-list-pink, 1);
$md-clr-pink-100: nth($md-list-pink, 2);
$md-clr-pink-200: nth($md-list-pink, 3);
$md-clr-pink-300: nth($md-list-pink, 4);
$md-clr-pink-400: nth($md-list-pink, 5);
$md-clr-pink-500: nth($md-list-pink, 6);
$md-clr-pink-600: nth($md-list-pink, 7);
$md-clr-pink-700: nth($md-list-pink, 8);
$md-clr-pink-800: nth($md-list-pink, 9);
$md-clr-pink-900: nth($md-list-pink, 10);
$md-clr-pink-A100: nth($md-list-pink, 11);
$md-clr-pink-A200: nth($md-list-pink, 12);
$md-clr-pink-A400: nth($md-list-pink, 13);
$md-clr-pink-A700: nth($md-list-pink, 14);

// Purple
$md-clr-purple: nth($md-list-purple, 6);

$md-clr-purple-50: nth($md-list-purple, 1);
$md-clr-purple-100: nth($md-list-purple, 2);
$md-clr-purple-200: nth($md-list-purple, 3);
$md-clr-purple-300: nth($md-list-purple, 4);
$md-clr-purple-400: nth($md-list-purple, 5);
$md-clr-purple-500: nth($md-list-purple, 6);
$md-clr-purple-600: nth($md-list-purple, 7);
$md-clr-purple-700: nth($md-list-purple, 8);
$md-clr-purple-800: nth($md-list-purple, 9);
$md-clr-purple-900: nth($md-list-purple, 10);
$md-clr-purple-A100: nth($md-list-purple, 11);
$md-clr-purple-A200: nth($md-list-purple, 12);
$md-clr-purple-A400: nth($md-list-purple, 13);
$md-clr-purple-A700: nth($md-list-purple, 14);

// Deep Purple
$md-clr-deep-purple: nth($md-list-deep-purple, 6);

$md-clr-deep-purple-50: nth($md-list-deep-purple, 1);
$md-clr-deep-purple-100: nth($md-list-deep-purple, 2);
$md-clr-deep-purple-200: nth($md-list-deep-purple, 3);
$md-clr-deep-purple-300: nth($md-list-deep-purple, 4);
$md-clr-deep-purple-400: nth($md-list-deep-purple, 5);
$md-clr-deep-purple-500: nth($md-list-deep-purple, 6);
$md-clr-deep-purple-600: nth($md-list-deep-purple, 7);
$md-clr-deep-purple-700: nth($md-list-deep-purple, 8);
$md-clr-deep-purple-800: nth($md-list-deep-purple, 9);
$md-clr-deep-purple-900: nth($md-list-deep-purple, 10);
$md-clr-deep-purple-A100: nth($md-list-deep-purple, 11);
$md-clr-deep-purple-A200: nth($md-list-deep-purple, 12);
$md-clr-deep-purple-A400: nth($md-list-deep-purple, 13);
$md-clr-deep-purple-A700: nth($md-list-deep-purple, 14);

// Indigo
$md-clr-indigo: nth($md-list-indigo, 6);

$md-clr-indigo-50: nth($md-list-indigo, 1);
$md-clr-indigo-100: nth($md-list-indigo, 2);
$md-clr-indigo-200: nth($md-list-indigo, 3);
$md-clr-indigo-300: nth($md-list-indigo, 4);
$md-clr-indigo-400: nth($md-list-indigo, 5);
$md-clr-indigo-500: nth($md-list-indigo, 6);
$md-clr-indigo-600: nth($md-list-indigo, 7);
$md-clr-indigo-700: nth($md-list-indigo, 8);
$md-clr-indigo-800: nth($md-list-indigo, 9);
$md-clr-indigo-900: nth($md-list-indigo, 10);
$md-clr-indigo-A100: nth($md-list-indigo, 11);
$md-clr-indigo-A200: nth($md-list-indigo, 12);
$md-clr-indigo-A400: nth($md-list-indigo, 13);
$md-clr-indigo-A700: nth($md-list-indigo, 14);

// Blue
$md-clr-blue: nth($md-list-blue, 6);

$md-clr-blue-50: nth($md-list-blue, 1);
$md-clr-blue-100: nth($md-list-blue, 2);
$md-clr-blue-200: nth($md-list-blue, 3);
$md-clr-blue-300: nth($md-list-blue, 4);
$md-clr-blue-400: nth($md-list-blue, 5);
$md-clr-blue-500: nth($md-list-blue, 6);
$md-clr-blue-600: nth($md-list-blue, 7);
$md-clr-blue-700: nth($md-list-blue, 8);
$md-clr-blue-800: nth($md-list-blue, 9);
$md-clr-blue-900: nth($md-list-blue, 10);
$md-clr-blue-A100: nth($md-list-blue, 11);
$md-clr-blue-A200: nth($md-list-blue, 12);
$md-clr-blue-A400: nth($md-list-blue, 13);
$md-clr-blue-A700: nth($md-list-blue, 14);

// Light Blue
$md-clr-light-blue: nth($md-list-light-blue, 6);

$md-clr-light-blue-50: nth($md-list-light-blue, 1);
$md-clr-light-blue-100: nth($md-list-light-blue, 2);
$md-clr-light-blue-200: nth($md-list-light-blue, 3);
$md-clr-light-blue-300: nth($md-list-light-blue, 4);
$md-clr-light-blue-400: nth($md-list-light-blue, 5);
$md-clr-light-blue-500: nth($md-list-light-blue, 6);
$md-clr-light-blue-600: nth($md-list-light-blue, 7);
$md-clr-light-blue-700: nth($md-list-light-blue, 8);
$md-clr-light-blue-800: nth($md-list-light-blue, 9);
$md-clr-light-blue-900: nth($md-list-light-blue, 10);
$md-clr-light-blue-A100: nth($md-list-light-blue, 11);
$md-clr-light-blue-A200: nth($md-list-light-blue, 12);
$md-clr-light-blue-A400: nth($md-list-light-blue, 13);
$md-clr-light-blue-A700: nth($md-list-light-blue, 14);

// Cyan
$md-clr-cyan: nth($md-list-cyan, 6);

$md-clr-cyan-50: nth($md-list-cyan, 1);
$md-clr-cyan-100: nth($md-list-cyan, 2);
$md-clr-cyan-200: nth($md-list-cyan, 3);
$md-clr-cyan-300: nth($md-list-cyan, 4);
$md-clr-cyan-400: nth($md-list-cyan, 5);
$md-clr-cyan-500: nth($md-list-cyan, 6);
$md-clr-cyan-600: nth($md-list-cyan, 7);
$md-clr-cyan-700: nth($md-list-cyan, 8);
$md-clr-cyan-800: nth($md-list-cyan, 9);
$md-clr-cyan-900: nth($md-list-cyan, 10);
$md-clr-cyan-A100: nth($md-list-cyan, 11);
$md-clr-cyan-A200: nth($md-list-cyan, 12);
$md-clr-cyan-A400: nth($md-list-cyan, 13);
$md-clr-cyan-A700: nth($md-list-cyan, 14);

// Teal
$md-clr-teal: nth($md-list-teal, 6);

$md-clr-teal-50: nth($md-list-teal, 1);
$md-clr-teal-100: nth($md-list-teal, 2);
$md-clr-teal-200: nth($md-list-teal, 3);
$md-clr-teal-300: nth($md-list-teal, 4);
$md-clr-teal-400: nth($md-list-teal, 5);
$md-clr-teal-500: nth($md-list-teal, 6);
$md-clr-teal-600: nth($md-list-teal, 7);
$md-clr-teal-700: nth($md-list-teal, 8);
$md-clr-teal-800: nth($md-list-teal, 9);
$md-clr-teal-900: nth($md-list-teal, 10);
$md-clr-teal-A100: nth($md-list-teal, 11);
$md-clr-teal-A200: nth($md-list-teal, 12);
$md-clr-teal-A400: nth($md-list-teal, 13);
$md-clr-teal-A700: nth($md-list-teal, 14);

// Green
$md-clr-green: nth($md-list-green, 6);

$md-clr-green-50: nth($md-list-green, 1);
$md-clr-green-100: nth($md-list-green, 2);
$md-clr-green-200: nth($md-list-green, 3);
$md-clr-green-300: nth($md-list-green, 4);
$md-clr-green-400: nth($md-list-green, 5);
$md-clr-green-500: nth($md-list-green, 6);
$md-clr-green-600: nth($md-list-green, 7);
$md-clr-green-700: nth($md-list-green, 8);
$md-clr-green-800: nth($md-list-green, 9);
$md-clr-green-900: nth($md-list-green, 10);
$md-clr-green-A100: nth($md-list-green, 11);
$md-clr-green-A200: nth($md-list-green, 12);
$md-clr-green-A400: nth($md-list-green, 13);
$md-clr-green-A700: nth($md-list-green, 14);

// Light Green
$md-clr-light-green: nth($md-list-light-green, 6);

$md-clr-light-green-50: nth($md-list-light-green, 1);
$md-clr-light-green-100: nth($md-list-light-green, 2);
$md-clr-light-green-200: nth($md-list-light-green, 3);
$md-clr-light-green-300: nth($md-list-light-green, 4);
$md-clr-light-green-400: nth($md-list-light-green, 5);
$md-clr-light-green-500: nth($md-list-light-green, 6);
$md-clr-light-green-600: nth($md-list-light-green, 7);
$md-clr-light-green-700: nth($md-list-light-green, 8);
$md-clr-light-green-800: nth($md-list-light-green, 9);
$md-clr-light-green-900: nth($md-list-light-green, 10);
$md-clr-light-green-A100: nth($md-list-light-green, 11);
$md-clr-light-green-A200: nth($md-list-light-green, 12);
$md-clr-light-green-A400: nth($md-list-light-green, 13);
$md-clr-light-green-A700: nth($md-list-light-green, 14);

// Lime
$md-clr-lime: nth($md-list-lime, 6);

$md-clr-lime-50: nth($md-list-lime, 1);
$md-clr-lime-100: nth($md-list-lime, 2);
$md-clr-lime-200: nth($md-list-lime, 3);
$md-clr-lime-300: nth($md-list-lime, 4);
$md-clr-lime-400: nth($md-list-lime, 5);
$md-clr-lime-500: nth($md-list-lime, 6);
$md-clr-lime-600: nth($md-list-lime, 7);
$md-clr-lime-700: nth($md-list-lime, 8);
$md-clr-lime-800: nth($md-list-lime, 9);
$md-clr-lime-900: nth($md-list-lime, 10);
$md-clr-lime-A100: nth($md-list-lime, 11);
$md-clr-lime-A200: nth($md-list-lime, 12);
$md-clr-lime-A400: nth($md-list-lime, 13);
$md-clr-lime-A700: nth($md-list-lime, 14);

//Yellow
$md-clr-yellow: nth($md-list-yellow, 6);

$md-clr-yellow-50: nth($md-list-yellow, 1);
$md-clr-yellow-100: nth($md-list-yellow, 2);
$md-clr-yellow-200: nth($md-list-yellow, 3);
$md-clr-yellow-300: nth($md-list-yellow, 4);
$md-clr-yellow-400: nth($md-list-yellow, 5);
$md-clr-yellow-500: nth($md-list-yellow, 6);
$md-clr-yellow-600: nth($md-list-yellow, 7);
$md-clr-yellow-700: nth($md-list-yellow, 8);
$md-clr-yellow-800: nth($md-list-yellow, 9);
$md-clr-yellow-900: nth($md-list-yellow, 10);
$md-clr-yellow-A100: nth($md-list-yellow, 11);
$md-clr-yellow-A200: nth($md-list-yellow, 12);
$md-clr-yellow-A400: nth($md-list-yellow, 13);
$md-clr-yellow-A700: nth($md-list-yellow, 14);

// Amber
$md-clr-amber: nth($md-list-amber, 6);

$md-clr-amber-50: nth($md-list-amber, 1);
$md-clr-amber-100: nth($md-list-amber, 2);
$md-clr-amber-200: nth($md-list-amber, 3);
$md-clr-amber-300: nth($md-list-amber, 4);
$md-clr-amber-400: nth($md-list-amber, 5);
$md-clr-amber-500: nth($md-list-amber, 6);
$md-clr-amber-600: nth($md-list-amber, 7);
$md-clr-amber-700: nth($md-list-amber, 8);
$md-clr-amber-800: nth($md-list-amber, 9);
$md-clr-amber-900: nth($md-list-amber, 10);
$md-clr-amber-A100: nth($md-list-amber, 11);
$md-clr-amber-A200: nth($md-list-amber, 12);
$md-clr-amber-A400: nth($md-list-amber, 13);
$md-clr-amber-A700: nth($md-list-amber, 14);

// Orange
$md-clr-orange: nth($md-list-orange, 6);

$md-clr-orange-50: nth($md-list-orange, 1);
$md-clr-orange-100: nth($md-list-orange, 2);
$md-clr-orange-200: nth($md-list-orange, 3);
$md-clr-orange-300: nth($md-list-orange, 4);
$md-clr-orange-400: nth($md-list-orange, 5);
$md-clr-orange-500: nth($md-list-orange, 6);
$md-clr-orange-600: nth($md-list-orange, 7);
$md-clr-orange-700: nth($md-list-orange, 8);
$md-clr-orange-800: nth($md-list-orange, 9);
$md-clr-orange-900: nth($md-list-orange, 10);
$md-clr-orange-A100: nth($md-list-orange, 11);
$md-clr-orange-A200: nth($md-list-orange, 12);
$md-clr-orange-A400: nth($md-list-orange, 13);
$md-clr-orange-A700: nth($md-list-orange, 14);

// Deep Orange
$md-clr-deep-orange: nth($md-list-deep-orange, 6);

$md-clr-deep-orange-50: nth($md-list-deep-orange, 1);
$md-clr-deep-orange-100: nth($md-list-deep-orange, 2);
$md-clr-deep-orange-200: nth($md-list-deep-orange, 3);
$md-clr-deep-orange-300: nth($md-list-deep-orange, 4);
$md-clr-deep-orange-400: nth($md-list-deep-orange, 5);
$md-clr-deep-orange-500: nth($md-list-deep-orange, 6);
$md-clr-deep-orange-600: nth($md-list-deep-orange, 7);
$md-clr-deep-orange-700: nth($md-list-deep-orange, 8);
$md-clr-deep-orange-800: nth($md-list-deep-orange, 9);
$md-clr-deep-orange-900: nth($md-list-deep-orange, 10);
$md-clr-deep-orange-A100: nth($md-list-deep-orange, 11);
$md-clr-deep-orange-A200: nth($md-list-deep-orange, 12);
$md-clr-deep-orange-A400: nth($md-list-deep-orange, 13);
$md-clr-deep-orange-A700: nth($md-list-deep-orange, 14);

// Brown
$md-clr-brown: nth($md-list-brown, 6);

$md-clr-brown-50: nth($md-list-brown, 1);
$md-clr-brown-100: nth($md-list-brown, 2);
$md-clr-brown-200: nth($md-list-brown, 3);
$md-clr-brown-300: nth($md-list-brown, 4);
$md-clr-brown-400: nth($md-list-brown, 5);
$md-clr-brown-500: nth($md-list-brown, 6);
$md-clr-brown-600: nth($md-list-brown, 7);
$md-clr-brown-700: nth($md-list-brown, 8);
$md-clr-brown-800: nth($md-list-brown, 9);
$md-clr-brown-900: nth($md-list-brown, 10);

// Grey
$md-clr-grey: nth($md-list-grey, 6);

$md-clr-grey-50: nth($md-list-grey, 1);
$md-clr-grey-100: nth($md-list-grey, 2);
$md-clr-grey-200: nth($md-list-grey, 3);
$md-clr-grey-300: nth($md-list-grey, 4);
$md-clr-grey-400: nth($md-list-grey, 5);
$md-clr-grey-500: nth($md-list-grey, 6);
$md-clr-grey-600: nth($md-list-grey, 7);
$md-clr-grey-700: nth($md-list-grey, 8);
$md-clr-grey-800: nth($md-list-grey, 9);
$md-clr-grey-900: nth($md-list-grey, 10);

// Blue Grey
$md-clr-blue-grey: nth($md-list-blue-grey, 6);

$md-clr-blue-grey-50: nth($md-list-blue-grey, 1);
$md-clr-blue-grey-100: nth($md-list-blue-grey, 2);
$md-clr-blue-grey-200: nth($md-list-blue-grey, 3);
$md-clr-blue-grey-300: nth($md-list-blue-grey, 4);
$md-clr-blue-grey-400: nth($md-list-blue-grey, 5);
$md-clr-blue-grey-500: nth($md-list-blue-grey, 6);
$md-clr-blue-grey-600: nth($md-list-blue-grey, 7);
$md-clr-blue-grey-700: nth($md-list-blue-grey, 8);
$md-clr-blue-grey-800: nth($md-list-blue-grey, 9);
$md-clr-blue-grey-900: nth($md-list-blue-grey, 10);

// UI Color Application
// --------------------------------------------------------------------------

// Typography
$md-clr-ui-display-4: $md-clr-grey-600;
$md-clr-ui-display-3: $md-clr-grey-600;
$md-clr-ui-display-2: $md-clr-grey-600;
$md-clr-ui-display-1: $md-clr-grey-600;
$md-clr-ui-headline: $md-clr-grey-900;
$md-clr-ui-title: $md-clr-grey-900;
$md-clr-ui-subhead-1: $md-clr-grey-900;
$md-clr-ui-body-2: $md-clr-grey-900;
$md-clr-ui-body-1: $md-clr-grey-900;
$md-clr-ui-caption: $md-clr-grey-600;
$md-clr-ui-menu: $md-clr-grey-900;
$md-clr-ui-button: $md-clr-grey-900;
