/**
 *
 * @group Components
 * @subgroup Media, images and visuals
 *
 * @description They are containers of media elements
 *
 * @base .#{$class-prefix}-pb-media Media
 *
 * @modifier .#{$class-prefix}-media--fixed Fixed media | media is fixed on the top of the page
 * @modifier .#{$class-prefix}-media--1x1 1x1 sized media
 * @modifier .#{$class-prefix}-media--3x2 3x2 media
 *
 * @example <div class="pb-media [modifierClass]"><img src=""/></div>
 *
 * @markup
 * <div class="pb-media [modifierClass] styleguide-helper">
     <img class="pb-media_content styleguide-helper" src="https://m.fahrrad.de/shared/teasers/oNNEGbPwixJXQDWAg.jpg">
 * </div>
 *
 */

// container for fixed ration mediacontent, 16x9
.#{$class-prefix}-media {
    position: relative;
    display: block;
    padding-bottom: 56.25%;
    height: auto;
}

.#{$class-prefix}-media--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: translate(0, 0);
}

.#{$class-prefix}-media--1x1 {
    padding-bottom: 100%;
}

.#{$class-prefix}-media--16x9 {
    padding-bottom: 56.25%;
}

.#{$class-prefix}-media--2x1 {
    padding-bottom: 50%;
}

.#{$class-prefix}-media--3x2 {
    padding-bottom: 67%;
}

.#{$class-prefix}-media_content,
.#{$class-prefix}-media_content > img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin: 0 !important;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.#{$class-prefix}-media_content--nocover {
    object-fit: inherit;
}
