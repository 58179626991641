/**
 *
 * @group Components
 * @subgroup Grid list
 *
 * @description Component not used. Grid Lists are best suited for presenting homogeneous data, typically images. Each item in a grid list is called a tile. Tiles maintain consistent width, height, and padding across screen sizes.
 *
 * @base .#{$class-prefix}-gridlist
 *
 *
 */

.#{$class-prefix}-gridlist {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    padding: getGrid(0.5px);
    width: 100%;
    height: auto;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    flex-flow: row;
}

.#{$class-prefix}-gridlist_portrait {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50%);
}

.#{$class-prefix}-gridlist_tile {
    margin: getGrid(0.5px);
    padding: 0;
    height: auto;
    flex: 1 1 100%;
}

.#{$class-prefix}-gridlist_tile--wide {
    flex: 1 1 100% !important;
}

$md-gradient-gridfooter: rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.5) 55%,
    rgba(0, 0, 0, 0.3) 80%, transparent 100%;
$md-gradient-gridfooter2: rgba(0, 0, 0, 0.75) 0, rgba(0, 0, 0, 0.4) 70%,
    rgba(0, 0, 0, 0.3) 80%, transparent 100%;

.#{$class-prefix}-gridlist_tile-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 48px 16px 24px;
    width: 100%;
    height: auto;
    background: linear-gradient(to top, $md-gradient-gridfooter);
    color: getColor(text, light);
    text-transform: uppercase;
}

.#{$class-prefix}-gridlist_tile-footer_head {
    @include getFontWeight(medium);

    font-size: 18px;
    line-height: 1.25;
}

.#{$class-prefix}-gridlist_tile-footer_sub {
    font-weight: normal;
    font-size: 14px;
    font-family: getTypography(font);
    line-height: 1.25;
}

.#{$class-prefix}-gridlist_tile--wide,
.#{$class-prefix}-media--16x9a {
    .#{$class-prefix}-gridlist_tile-footer_head {
        font-size: 7.5vw;
    }

    .#{$class-prefix}-gridlist_tile-footer_sub {
        font-size: 4vw;
    }
}

.#{$class-prefix}-gridlist_tile-footer--top {
    top: 0;
    bottom: initial;
    padding-top: 24px;
    padding-bottom: 48px;
    background: linear-gradient(to bottom, $md-gradient-gridfooter);
}

.#{$class-prefix}-gridlist_tile-footer--middle {
    top: 25%;
    bottom: initial;
    padding-top: 10%;
    //  transform: translateY(-50%);

    padding-bottom: 0%;
    height: 75%;
    background: linear-gradient(to top, $md-gradient-gridfooter2);
}

@media screen and (min-width: getBreakpoints(phone-s)) {
    .#{$class-prefix}-gridlist_tile {
        flex: 1 1 calc(50% - #{getGrid(2px)});
    }

    .#{$class-prefix}-gridlist_tile--small {
        min-width: 151px;
        max-width: 151px;
        padding: getGrid(1px);
    }
}

@media screen and (min-width: getBreakpoints(phone-l)) {
    .#{$class-prefix}-gridlist_tile,
    .#{$class-prefix}-gridlist_tile--wide {
        flex: 1 !important;
        min-width: calc(33.33333% - #{getGrid(2px)});
    }

    .#{$class-prefix}-gridlist_portrait {
        flex: 2;
        flex-direction: row;
    }

    .#{$class-prefix}-gridlist_tile--small {
        min-width: 151px;
        max-width: 151px;
        padding: getGrid(1px);
    }

    .#{$class-prefix}-gridlist_tile--wide {
        .#{$class-prefix}-gridlist_tile-footer_head {
            font-size: 18px;
        }

        .#{$class-prefix}-gridlist_tile-footer_sub {
            font-size: 14px;
        }
    }
}

.#{$class-prefix}-mediagrid {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.#{$class-prefix}-mediagrid_tile {
    position: relative;
    min-width: 50%;
    flex: 1;
}

@media screen and (min-width: getBreakpoints(phone-l)) {
    .#{$class-prefix}-mediagrid_tile {
        flex: 1;
        min-width: 33.3333%;
    }

    .#{$class-prefix}-gridlist_portrait {
        flex: 1;
        min-width: 66.6666666%;
    }

    .#{$class-prefix}-media--2x1 {
        padding-bottom: 100%;
    }

    .#{$class-prefix}-gridlist--teaserv1,
    .#{$class-prefix}-gridlist--teaserv2 {
        .#{$class-prefix}-gridlist_tile-footer_sub {
            display: none;
        }
    }

    .#{$class-prefix}-media--merchant {
        padding-bottom: 70vh !important;
    }
}

@media screen and (max-width: getBreakpoints(phone-xs)) {
    .#{$class-prefix}-gridlist_tile--wide {
        .#{$class-prefix}-media--1x1 {
            padding-bottom: 56.25%;
        }
    }

    .#{$class-prefix}-gridlist_tile--portrait {
        .#{$class-prefix}-media--1x1 {
            padding-bottom: 156.25%;
        }
    }
}

@media screen and (max-width: getBreakpoints(phone-m) - 1px) {
    .#{$class-prefix}-gridlist_tile--portrait {
        flex: 1 1 calc(50% - 7.5px);
    }
}

@media screen and (min-width: getBreakpoints(phone-m)) and (max-width: 399px) {
    .#{$class-prefix}-gridlist_tile--portrait {
        flex: 1 1 calc(50% - 8.5px);
    }
}

@media screen and (min-width: 400px) and (max-width: getBreakpoints(phone-l) - 1px) {
    .#{$class-prefix}-gridlist_tile--portrait {
        flex: 1 1 calc(50% - 9.5px);
    }
}
