/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Typography variables
 */

// base helper, ini
$typography-font: 'Roboto' !default;
$typography-font-fallback: 'Arial, Helvetica, sans-serif' !default;
$map-fonts: (
    font: "'#{$typography-font}', #{$typography-font-fallback}",
    font-path: '/fonts/Roboto'
);

/**
 * @module Typography functions
 */

// base values function
@function getTypography($type, $value: '') {
    $check: $type;
    $check2: $type + '-' + $value;

    @if map-has-key($map-fonts, $check2) {
        @return unquote(map-get($map-fonts, $check2));
    } @else {
        @if map-has-key($map-fonts, $check) {
            @return unquote(map-get($map-fonts, $check));
        } @else {
            @warn 'Unfortunately, no value could be retrieved from `#{$type}`. '+'Please make sure it is defined in `$typography-font` map.';
        }
    }
}

/**
 * @module Typography mixins
 */

@mixin getTypography($typo: 'body-2') {
    @if $typo==display4 {
        font-family: getTypography(font);

        @include getFontSize(display4);
        @include getFontWeight(light);
        @include getFontLineheight(display4);
    } @else if $typo==display3 {
        font-family: getTypography(font);

        @include getFontSize(display3);
        @include getFontLineheight(display3);
        @include getFontWeight(regular);
    } @else if $typo==display2 {
        font-family: getTypography(font);

        @include getFontSize(display2);
        @include getFontLineheight(display2);
        @include getFontWeight(regular);
    } @else if $typo==display1 {
        font-family: getTypography(font);

        @include getFontSize(display1);
        @include getFontLineheight(display1);
        @include getFontWeight(regular);
    } @else if $typo==headline {
        margin-bottom: getGrid(2px);
        font-family: getTypography(font);

        @include getFontSize(headline);
        @include getFontLineheight(headline);
        @include getFontWeight(regular);
    } @else if $typo==title {
        font-family: getTypography(font);

        @include getFontSize(title);
        @include getFontLineheight(title);
        @include getFontWeight(medium);
    } @else if $typo==subheading {
        font-family: getTypography(font);

        @include getFontSize(subheading);
        @include getFontLineheight(subheading);
        @include getFontWeight(regular);
    } @else if $typo==subheading-2 {
        font-family: getTypography(font);

        @include getFontSize(subheading);
        @include getFontLineheight(subheading);
        @include getFontWeight(medium);
    } @else if $typo==body {
        font-family: getTypography(font);

        @include getFontSize(body);
        @include getFontLineheight(body-1);
        @include getFontWeight(regular);
    } @else if $typo==body-2 {
        font-family: getTypography(font);

        @include getFontSize(body);
        @include getFontLineheight(body-2);
        @include getFontWeight(medium);
    } @else if $typo==body-1 {
        font-family: getTypography(font);

        @include getFontSize(body);
        @include getFontLineheight(body-1);
        @include getFontWeight(regular);
    } @else if $typo==caption {
        font-family: getTypography(font);

        @include getFontSize(caption);
        @include getFontLineheight(caption);
        @include getFontWeight(regular);
    } @else if $typo==secondary {
        font-family: getTypography(font);

        @include getFontSize(secondary);
        @include getFontLineheight(secondary);
        @include getFontWeight(regular);
    } @else if $typo==button {
        font-family: getTypography(font);

        @include getFontSize(body);
        @include getFontWeight(medium);

        line-height: getGrid(9.5px);
        text-transform: uppercase;
    } @else if $typo==chip {
        font-family: getTypography(font);

        @include getFontSize(body);
        @include getFontWeight(regular);

        line-height: 1;
    } @else if $typo==tabs {
        font-family: getTypography(font);

        @include getFontSize(body);
        @include getFontWeight(medium);

        line-height: 1;
        text-transform: uppercase;
        text-align: center;
    }
}

/**
 * @module Font size mixin
 */

/*
 * size   style
 *
 * 112    display 4
 * 56     display 3
 * 45     display 2
 * 34     display 1
 * 24     headline
 * 20     title
 * 16     subheading
 * 14     body
 * 12     caption
 * 11     secondary (custom size)
 *
 */

// font sizes map
$map-font-sizes: (
    display4: 112,
    display3: 56,
    display2: 45,
    display1: 34,
    headline: 24,
    title: 20,
    subheading: 16,
    body: 14,
    caption: 12,
    secondary: 11
);

// font sizes mixin
@mixin getFontSize($size, $modifier: '') {
    @if map-has-key($map-font-sizes, $size) {
        font-size: #{map-get($map-font-sizes, $size) / 16}rem #{$modifier};
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$size}`. '+'Please make sure it is defined in `$map-font-sizes` map.';
    }
}

/* line heights
 *
 * size    style
 *
 * 1       display 4
 * 1       display 3
 * 48      display 2
 * 40      display 1
 * 32      headline
 * 28      title
 * 28      subheading 2
 * 24      subheading
 * 24      body 2
 * 20      body
 * 1       caption
 * 1       secondary (custom size)
 *
 */

// font sizes map
$map-font-lineheights: (
    display4: 1,
    display3: 1,
    display2: 48px,
    display1: 40px,
    headline: 32px,
    title: 28px,
    subheading-2: 28px,
    subheading-1: 24px,
    subheading: 24px,
    body-2: 24px,
    body-1: 20px,
    body: 20px,
    chip: 18px,
    caption: 1,
    secondary: 1
);

// font sizes mixin
@mixin getFontLineheight($lineheight, $modifier: '') {
    @if map-has-key($map-font-lineheights, $lineheight) {
        line-height: #{map-get($map-font-lineheights, $lineheight)}
            #{$modifier};
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$lineheight}`. '+'Please make sure it is defined in `$map-font-lineheights` map.';
    }
}

/**
 * @module Font weight mixin
 */

/*
 * Font   Weight
 * 100    Thin
 * 300    Light
 * 400    Normal | Regular
 * 500    Medium
 * 700    Bold
 * 900    Black
*/

// font weights map
$typography-font-weights: (
    thin: 100,
    light: 300,
    normal: 400,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
    ultrabold: 900
);

// font weights mixin
@mixin getFontWeight($weight, $modifier: '') {
    @if map-has-key($typography-font-weights, $weight) {
        font-weight: #{map-get($typography-font-weights, $weight)} #{$modifier};
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$weight}`. '+'Please make sure it is defined in `$typography-font-weights` map.';
    }
}

/**
 * @module Typography functions
 */

// base values function
@function getTypography($type, $value: '') {
    $check: $type;
    $check2: $type + '-' + $value;

    @if map-has-key($map-fonts, $check2) {
        @return unquote(map-get($map-fonts, $check2));
    } @else {
        @if map-has-key($map-fonts, $check) {
            @return unquote(map-get($map-fonts, $check));
        } @else {
            @warn 'Unfortunately, no value could be retrieved from `#{$type}`. '+'Please make sure it is defined in `$typography-font` map.';
        }
    }
}
