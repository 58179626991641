/**
 *
 * @group Components
 * @subgroup Dialog
 *
 * @description Dialogs contain text and UI controls focused on a specific task. They inform users about critical information, require users to make decisions, or involve multiple tasks.
 *
 * @base .#{$class-prefix}-dialog Default dialog
 *
 * @modifier .#{$class-prefix}-dialog--animate Animation | Show/Hide dialog
 * @modifier .#{$class-prefix}-dialog--top Top alignment | Align dialog on top of the screen
 * @modifier .#{$class-prefix}-dialog--max Max dimensions | Max dialog dimensions with padding
 *
 * @example <div class="pb-dialog">... TEMPLATE-CODE</div>
 *
 * @markup
 * <div style="width:200px !important;" class="styleguide-helper pb-dialog [modifierClass]" id="js-loginPasswordDialog">
 *     <div class="pb-theme_light">
 *         <div class="pb-toolbar">
 *             <button data-dialog="_#js-loginPasswordDialog">
 *               <svg class="pb-icon" viewBox="-4 -4 32 32">
 *                 <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-close"></use>
 *              </svg>
 *            </button>
 *             <div class="pb-toolbar_title">Passwort vergessen</div>
 *         </div>
 *     </div>
 *     <div class="pb-list-medium">
 *         <div class="pb-list_item">
 *             <div class="pb-list_body pb-typo--xhigh pb-is-multiline">Bitte gib die zu deinem Konto gehörende E-Mail Adresse ein und wir schicken dir dein neues Passwort umgehend per E-Mail zu.</div>
 *         </div>
 *         <div class="pb-list_item pb-list_item--submit pb-flex--right">
 *             <button class="pb-button pb-button--text">Verstanden</button>
 *         </div>
 *     </div>
 * </div>
 *
 */

.#{$class-prefix}-dialog {
    position: fixed;
    right: 50%;
    top: 50%;
    // display: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
    min-width: getGrid(70px);
    max-width: getBreakpoints(tablet-s);

    @include getDepth(24);

    width: 75%;
    height: auto;
    max-height: 90%;
    border-radius: pbRadius(radius, medium);
    background-color: getColor(content);
    opacity: 0;
    transition: opacity 0.3s getAnimation(ease),
        transform 0.3s getAnimation(ease);
    transform: scale(0.85, 0.85) translate(50%, -50%);
    transform-origin: 75% 75%;
    flex: 1;
}

// animation class for dialog visibility
.#{$class-prefix}-dialog--animate {
    opacity: 1;
    transform: scale(1, 1) translate(50%, -50%);
}

// hack for password_forgotten with pop keyboard
.#{$class-prefix}-dialog--top {
    top: getGrid(4px) !important;
}

//
.#{$class-prefix}-dialog--max {
    width: 95%;
    height: 95%;
}
