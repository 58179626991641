/**
 *
 * @group Components
 * @subgroup Lists
 *
 * @description Lists are used to present multiple items vertically as a single continuous element. They can be configured for many uses such as a contacts list, nested lists, etc.
 *
 * @base .#{$class-prefix}-list List
 *
 * @modifier .#{$class-prefix}-list--medium List medium | Margins on top and bottom
 * @modifier .#{$class-prefix}-list--indent Indented list
 * @modifier .#{$class-prefix}-list--divider List with separators
 *
 * @example <div class="pb-list [modifierClass]">...TEMPLATE</div>
 *
 * @markup
 * <div class="pb-lis [modifierClass]">
 *    <div class="pb-list_item">
 *        <svg class="pb-icon" viewBox="-4 -4 32 32">
 *            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-account_circle"></use>
 *        </svg>
 *        <div class="pb-list_multiline">
 *            <div class="pb-list_header">Multiline item</div>
 *            <p class="pb-list_body">This body text can be as long as it wants. Use the medium modifier for the list.</p>
 *        </div>
 *    </div>
 * </div>
 *
 */

.#{$class-prefix}-list {
    display: block;
    margin: 0;
    padding: getGrid(2px) 0;
    width: 100%;
}

.#{$class-prefix}-list--medium {
    .#{$class-prefix}-list_item {
        .#{$class-prefix}-icon {
            margin-top: getGrid(2px);
        }

        .#{$class-prefix}-avatar {
            margin-top: getGrid(4px);
        }
    }
}

.#{$class-prefix}-list.#{$class-prefix}-is-divider {
    .#{$class-prefix}-list_item {
        border-top: 1px solid getColor(dividers);

        &:last-child {
            border-top: none;
        }
    }
}

// dividers
.#{$class-prefix}-list--divider {
    .#{$class-prefix}-list_item {
        border-top: 1px solid getColor(dividers);

        &:first-of-type {
            border-top: none;
        }
    }
}

.#{$class-prefix}-list--dividers .#{$class-prefix}-divider:last-of-type {
    display: none;
}

/**
 *
 * @group Components
 * @subgroup List item
 *
 * @description Single list item
 *
 * @base .#{$class-prefix}-list_item List
 *
 * @modifier .#{$class-prefix}-list_item--medium Medium item list
 * @modifier
 *
 * @example <div class="pb-list_item [modifierClass]"><div class="pb-list_header">Simple list item</div></div>
 *
 * @markup
 * <div class="pb-list [modifierClass]">
 *    <div class="pb-list_item">
 *        <svg class="pb-icon" viewBox="-4 -4 32 32">
 *            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-account_circle"></use>
 *        </svg>
 *    </div>
 *    <div class="pb-list_item">
 *       <div class="pb-list_header">Simple list item</div>
 *    </div>
 * </div>
 *
 */

.#{$class-prefix}-list_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: getGrid(1px);
    min-height: getGrid(12px);
    height: auto;
    color: inherit;
    text-decoration: none;

    @include getTransition(0.6s);

    &:active {
        text-decoration: none;
    }
}

.#{$class-prefix}-list_item--medium {
    min-height: getGrid(18px);

    .#{$class-prefix}-avatar {
        margin-top: getGrid(5px);
    }
}

// *** list helper

// helper for primary action area
.#{$class-prefix}-list_item .#{$class-prefix}-icon:first-child {
    margin-right: getGrid(2px);
}

.#{$class-prefix}-list_item .#{$class-prefix}-icon--invert:first-child {
    margin-right: getGrid(5.5px);
}

.#{$class-prefix}-list_item .#{$class-prefix}-icon--left:first-child {
    margin-right: getGrid(1px);
    margin-left: 0;
}

.#{$class-prefix}-list_item .#{$class-prefix}-icon--right:first-child {
    margin-right: 0;
    margin-left: getGrid(1px);
}

.#{$class-prefix}-list_item .#{$class-prefix}-icon--circle:first-child {
    margin-right: getGrid(3px);
    margin-left: getGrid(2px);
}

.#{$class-prefix}-list_item .#{$class-prefix}-avatar:first-child {
    margin-right: getGrid(1px);
}

// indent, outdent

.#{$class-prefix}-list--indent {
    .#{$class-prefix}-list_item::before {
        width: getGrid(14px);
        content: ' ';
    }

    .#{$class-prefix}-list_item--outdent::before {
        width: 0;
        content: '';
    }
}

// link style for all items in a list
.#{$class-prefix}-list--is-link .#{$class-prefix}-list_item:active,
.#{$class-prefix}-list--is-link .#{$class-prefix}-list_item--medium:active {
    background-color: getColor(grey, 200);
    color: getColor(primary, contrast);
    text-decoration: none;
    cursor: pointer;
    user-select: none;
}

// *** active list
.#{$class-prefix}-list_item--active {
    color: getColor(primary, contrast) !important;
    fill: getColor(primary, contrast) !important;

    .#{$class-prefix}-icon {
        fill: getColor(primary) !important;
    }
}

.#{$class-prefix}-is-nolink .#{$class-prefix}-list_item:active,
.#{$class-prefix}-is-nolink .#{$class-prefix}-list_item--medium:active {
    background-color: transparent;
    text-decoration: none;
    cursor: default;
    user-select: none;
}

// link style for one item in a list
.#{$class-prefix}-list_item.#{$class-prefix}-list--is-link:active,
.#{$class-prefix}-list_item--medium.#{$class-prefix}-list--is-link:active {
    background-color: nth($md-list-grey, 3);
    color: getColor(primary, contrast);
    text-decoration: none;
    cursor: pointer;
}

.#{$class-prefix}-list_item.#{$class-prefix}-is-nolink:active,
.#{$class-prefix}-list_item--medium.#{$class-prefix}-is-nolink:active {
    background-color: transparent;
    text-decoration: none;
    cursor: default;

    &:active {
        color: inherit;
    }
}

// a denser version of an list line
.#{$class-prefix}-list_item--dense {
    min-height: getGrid(9px);

    .#{$class-prefix}-control {
        height: getGrid(9px);
    }
}

// space for submit lines
// provide container for fixed submit lines in password_forgotten
.#{$class-prefix}-list_item--submit {
    bottom: 0;
    left: 0;
    padding: 0 getGrid(2px) 0 0 !important;
    height: getGrid(14px) !important;
    background: inherit;
}

// content container
// container for copy text in multiline container
.#{$class-prefix}-list_body {
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0 getGrid(4px) getGrid(2px) getGrid(3px);
    color: getColor(secondarytext);
    text-align: left;
    text-overflow: ellipsis;
    flex: 1;

    @include getTypography(body-1);
}

// standard class for one-line list-items
.#{$class-prefix}-list_header,
.#{$class-prefix}-list_header2,
.#{$class-prefix}-list_header-2 {
    display: inline-block;
    overflow: hidden;
    margin: 0;
    padding: 0 getGrid(3px) 0 getGrid(3px);
    height: auto;
    color: inherit;
    text-align: left;
    text-overflow: ellipsis;
    line-height: getGrid(6px);
    flex: 1;
}

.#{$class-prefix}-list_header {
    @include getTypography(subheading-2);
}

.#{$class-prefix}-list_header-2 {
    @include getTypography(body-2);
}

.#{$class-prefix}-list_header2 {
    @include getTypography(body-1);
    @include getFontLineheight(subheading-1);
}

/**
 *
 * @group Components
 * @subgroup List item
 *
 * @description Multiline list item
 *
 * @base .#{$class-prefix}-list_multiline Multiline list item
 *
 * @example <div class="pb-list_multiline"><div class="pb-list_header">Multiline item</div><p class="pb-list_body">This body text can be as long as it wants. Use the medium modifier for the list.</p></div>
 *
 * @markup
 * <div class="pb-list pb-list--medium">
 *    <div class="pb-list_multiline">
 *        <div class="pb-list_header">Multiline item</div>
 *        <p class="pb-list_body">This body text can be as long as it wants. Use the medium modifier for the list.</p>
 *    </div>
 *    <div class="pb-list_multiline">
 *        <div class="pb-list_header">Multiline item</div>
 *        <p class="pb-list_body">This body text can be as long as it wants. Use the medium modifier for the list.</p>
 *    </div>
 * </div>
 *
 */

// container needed for more than two lines (header,body)
.#{$class-prefix}-list_multiline {
    padding-top: getGrid(1px);
    padding-bottom: getGrid(1px);
    width: 100%;
    min-width: 0;
    text-align: left;
    white-space: normal;
    flex: 1;

    .#{$class-prefix}-list_header,
    .#{$class-prefix}-list_header2,
    .#{$class-prefix}-list_header-2 {
        padding-top: getGrid(2px);
    }
}

// unordered list
.#{$class-prefix}-unorderedlist {
    display: block;
    margin: 0 0 0 getGrid(1px);
    padding: 0 0 0 getGrid(4px);
    border-left: getGrid(1px) solid getColor(primary);
    list-style-type: none;
}

.#{$class-prefix}-unorderedlist_item {
    display: block;
}
