/**
 *
 * @group Components
 * @subgroup Toast & Snackbars
 *
 * @description Toasts (Android only) are primarily used for system messaging. They also display at the bottom of the screen, but may not be swiped off-screen.
 *
 * @base .#{$class-prefix}-toast Default toast
 *
 * @modifier .#{$class-prefix}-toast--animate Toast animation | Animation to show/hide the toast
 * @modifier
 *
 * @example <div class="pb-toast [modifierClass]">... TEMPLATE-CODE</div>
 *
 * @markup
 * <div class="styleguide-helper pb-toast [modifierClass] pb-theme_dark pb-margin_2">A simple toast.</div>
 *
 */

.#{$class-prefix}-toast {
    position: fixed;
    display: inline-block;
    top: calc(100vh - 25%);
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0 auto;
    padding: getGrid(3px) getGrid(8px);
    border-radius: getGrid(8px);
    width: auto;
    min-width: calc(100% - #{getGrid(16px)});
    background-color: getColor(background, dark);

    @include getTypography(subheading-2);

    color: getColor(text, light);

    @include getDepth(6);

    // hack to put snackbar above dialogfullscreen
    z-index: 2300 !important;
    text-align: center;
    opacity: 0;
    transition: opacity 1.2s getAnimation(ease);
    will-change: opacity;
}

.#{$class-prefix}-toast--animate {
    opacity: 0.85;
}

// *** adaptive gui
@media screen and (min-width: getBreakpoints(tablet-s)) {
    .#{$class-prefix}-toast {
        min-width: getBreakpoints(phone-m);
        max-width: getBreakpoints(phone-m);
    }
}
