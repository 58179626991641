/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Reset
 */

html {
    touch-action: manipulation;
}

html * {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    overflow: auto;
}

body,
input,
textarea,
select {
    font-size: unset;
}

iframe {
    box-sizing: border-box;
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
blockquote,
figure,
button {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

code {
    display: block;
}

li,
strong {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p {
    font-size: inherit;
    font-weight: inherit;
}

sup {
    box-sizing: border-box;
    position: relative;
    top: -0.5em;
    font-size: 65%;
    line-height: 0;
    vertical-align: baseline;
}

a,
button {
    box-sizing: border-box;
    color: inherit;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    border-radius: 0;
}

a {
    text-decoration: none;
}

button {
    display: inline-flex;
    overflow: visible;
    border: 0;
    align-self: center;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
    -webkit-font-smoothing: inherit;

    &:focus {
        outline: 0;
    }
}

::-moz-focus-inner {
    padding: 0;
    border: 0;
}

img {
    box-sizing: border-box;
    border: 0;
    user-select: none;
}

input,
textarea,
select {
    box-sizing: border-box;
    outline: none;
    background: none;
}

svg {
    box-sizing: border-box;
    touch-action: manipulation;
}

pre {
    margin: 0 0 1em 0;
}
