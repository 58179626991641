/**
 *
 * @group Components
 * @subgroup Buttons
 *
 * @description Floating action buttons (FAB) are used for a promoted action. They are distinguished by a circled icon floating above the UI and have motion behaviors that include morphing, launching, and a transferring anchor point.
 *
 * @base .#{$class-prefix}-fab Fab button
 *
 * @modifier .#{$class-prefix}-fab--disabled Inline button
 * @modifier .#{$class-prefix}-fab--mini Toolbar button
 *
 * @example <button class="pb-fab"><svg></button>
 *
 * @markup
 * <button class="pb-fab [modifierClass]">
 *    <svg class="pb-icon" viewBox="-4 -4 32 32">
 *        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pb-icon-filter_list"></use>
 *    </svg>
 * </button>
 *
 */

.#{$class-prefix}-fab-container {
    width: getGrid(14px);
    height: getGrid(14px);
}

.#{$class-prefix}-fab {
    // hack to have fabs beneath app/toolbars
    z-index: 350 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    @include getTypography(button);

    width: getGrid(14px);
    height: getGrid(14px);
    border: none;
    border-radius: 50%;
    background-color: getColor(secondary);
    text-decoration: none;
    letter-spacing: 0;
    //  line-height: getGrid(14px);
    cursor: pointer;

    @include getDepth(6);

    user-select: none;
    transition: all 0.6s getAnimation(ease);

    &:active {
        background-color: getColor(secondary);

        @include getDepth(12);

        transform: translate(0, #{getGrid(-0.5px)});
    }

    .#{$class-prefix}-icon {
        fill: getColor(text, secondary);

        &:active {
            background-color: initial;
        }
    }
}

.#{$class-prefix}-fab--mini {
    width: getGrid(10px);
    height: getGrid(10px);

    .#{$class-prefix}-icon {
        min-width: getGrid(9px);
        width: getGrid(9px);
        min-height: getGrid(9px);
        height: getGrid(9px);
        padding: getGrid(0.5px);
    }
}

// ** animate the fab when a snackbar is shown on small devices
@media screen and (min-width: getBreakpoints(phone-l)) {
    .#{$class-prefix}-fab--snackbar {
        transform: translate(0, 0) !important;
    }
}

// *** fab position for top, bottom and left, right
.#{$class-prefix}-fab--top {
    position: absolute;
    top: getGrid(4px);
    bottom: initial;
}

.#{$class-prefix}-fab--bottom {
    position: absolute;
    top: initial;
    bottom: getGrid(4px);
}

.#{$class-prefix}-fab--right {
    position: absolute;
    left: initial;
    right: getGrid(4px);
}

.#{$class-prefix}-fab--left {
    position: absolute;
    left: getGrid(4px);
    right: initial;
}

.#{$class-prefix}-fab--center-h {
    position: absolute;
    left: 50%;
    right: initial;
    transform: translateX(-50%);

    &:active {
        transform: translate(-50%, #{getGrid(-1px)});
    }
}

.#{$class-prefix}-fab--center-v {
    position: absolute;
    top: 50%;
    bottom: initial;
    transform: translateY(-50%);
}

.#{$class-prefix}-fab--center {
    position: absolute;
    top: 50%;
    bottom: auto;
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%);

    &:active {
        transform: translate(-50%, calc(-50% - #{getGrid(1px)}));
    }
}

// *** fab position for fab on seam
.#{$class-prefix}-fab--center-left-edge {
    position: absolute;
    top: 50%;
    bottom: initial;
    left: initial;
    right: initial;
    transform: translate(-50%, -50%);

    &:active {
        transform: translate(-50%, calc(-50% - #{getGrid(1px)}));
    }
}

.#{$class-prefix}-fab--center-right-edge {
    position: absolute;
    top: 50%;
    bottom: initial;
    right: 0;
    left: initial;
    transform: translate(-25%, -50%);

    &:active {
        transform: translate(-25%, calc(-50% - #{getGrid(1px)}));
    }
}

.#{$class-prefix}-fab--center-h-edge {
    position: absolute;
    top: initial;
    bottom: initial;
    right: initial;
    left: 50%;
    transform: translate(-50%, -50%);

    &:active {
        transform: translate(-50%, calc(-50% - #{getGrid(1px)}));
    }
}

.#{$class-prefix}-fab--left-edge {
    position: absolute;
    left: 0;
    right: initial;
    transform: translateX(-50%);
}

.#{$class-prefix}-fab--right-edge {
    position: absolute;
    left: initial;
    right: 0;
    transform: translateX(50%);
}

.#{$class-prefix}-fab--top-edge {
    position: absolute;
    top: initial;
    bottom: initial;
    transform: translateY(-50%);

    &:active {
        transform: translateY(calc(-50% - #{getGrid(1px)}));
    }
}

.#{$class-prefix}-fab--bottom-edge {
    position: absolute;
    top: initial;
    bottom: 0;
    transform: translateY(50%);

    &:active {
        transform: translateY(calc(50% - #{getGrid(1px)}));
    }
}
