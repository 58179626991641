/**
 *
 * @group Components
 * @subgroup Table
 *
 * @description Tables are used to display data and to organize it in a column/row layout.
 *
 * @base .#{$class-prefix}-table Simple table
 *
 * @example <table class="pb-table">... TEMPLATE</table>
 *
 * @markup
 * <table class="pb-table">
 *    <thead>
 *        <tr>
 *            <th>Nr.</th>
 *            <th>Name.</th>
 *            <th>Position</th>
 *        </tr>
 *    </thead>
 *    <tr>
 *        <td>1</td>
 *        <td>Picard</td>
 *        <td>Captain</td>
 *    </tr>
 *    <tr>
 *        <td>2</td>
 *        <td>Ryker</td>
 *        <td>Number two</td>
 *    </tr>
 *    <tr>
 *        <td>3</td>
 *        <td>Worf</td>
 *        <td>PITA</td>
 *    </tr>
 * </table>
 *
 */

$md-table-toolbar-height: getGrid(16px) !default;
$md-table-row-height: getGrid(12px) !default;
$md-table-footer-height: getGrid(14px) !default;

$md-table-dividers: 1px solid getColor(dividers);

$md-table-column-padding: getGrid(8px) !default;

.#{$class-prefix}-table {
    position: relative;
    border: none;
    border-collapse: collapse;
    white-space: nowrap;
    width: 100%;
    color: getColor(secondarytext);

    caption {
        height: getGrid(16px);
        text-align: left;

        @include getTypography(title);

        line-height: getGrid(16px);
        color: getColor(text);
    }

    thead {
        td {
            // background-color: nth($md-list-grey, 2);
            border: none;

            @include getFontWeight(medium);
        }
    }

    tbody {
        tr {
            position: relative;
            height: $md-table-row-height;
            transition-property: background-color;

            &.#{$class-prefix}-is-selected {
                background-color: getColor(grey, 200);
                color: getColor(text);
            }

            &:hover {
                background-color: getColor(grey, 300);
                color: getColor(text);
            }
        }
    }

    td,
    th {
        padding: 0 $md-table-column-padding/2;
        text-align: left;

        &:first-of-type {
            padding-left: getGrid(4px);
        }

        &:last-of-type {
            padding-right: getGrid(4px);
        }
    }

    td.#{$class-prefix}-is-num,
    th.#{$class-prefix}-is-num {
        text-align: right;
    }

    td {
        position: relative;
        min-height: $md-table-row-height;
        height: $md-table-row-height;
        border-top: $md-table-dividers;
        vertical-align: middle;
        line-height: 1.5;
        white-space: normal;
    }

    tr:first-of-type td {
        border-top: none;
    }

    tr:first-of-type th {
        border-bottom: $md-table-dividers;
    }

    tr:last-of-type td {
        border-bottom: none;
    }

    th {
        position: relative;
        height: $md-table-row-height;
        color: getColor(text);
        vertical-align: bottom;

        @include getFontWeight(medium);
        @include getFontSize(caption);

        text-overflow: ellipsis;
        line-height: $md-table-row-height;
    }
}

.#{$class-prefix}-icon--table-head {
    position: relative;
    top: getGrid(1px);
    margin: getGrid(-2px) 0 getGrid(-2px) getGrid(-1px);
    padding: 0;
    min-width: getGrid(9px);
    width: getGrid(9px);
    height: getGrid(9px);
    min-height: getGrid(9px);
    border-radius: 0;
    text-align: left;

    &:hover {
        background: transparent;
    }
}

/**
 *
 * @group Components
 * @subgroup Table
 *
 * @description Use this class for adding checkbox controls to the table.
 *
 * @base .#{$class-prefix}-table_control Table with controls
 *
 * @example <table class="pb-table">... TEMPLATE</table>
 *
 * @markup
 * <table class="pb-table">
 *    <thead>
 *        <tr>
 *            <th class="pb-table_control">
 *                <label class="pb-control">
 *                    <input type="checkbox" value="all">
 *                    <label></label>
 *                </label>
 *            </th>
 *            <th>Nr.</th>
 *            <th>Name.</th>
 *            <th>Position</th>
 *        </tr>
 *    </thead>
 *    <tr>
 *        <td class="pb-table_control">
 *            <label class="pb-control">
 *                <input type="checkbox" id="table_control_1" value="1">
 *                <label for="table_control_1"></label>
 *            </label>
 *        </td>
 *        <td>1</td>
 *        <td>Picard</td>
 *        <td>Captain</td>
 *    </tr>
 *    <tr>
 *        <td class="pb-table_control">
 *            <label class="pb-control">
 *                <input type="checkbox" id="table_control_2" value="2">
 *                <label for="table_control_2"></label>
 *            </label>
 *        </td>
 *        <td>2</td>
 *        <td>Ryker</td>
 *        <td>Number two</td>
 *    </tr>
 *    <tr>
 *        <td class="pb-table_control">
 *            <label class="pb-control">
 *                <input type="checkbox" id="table_control_3" value="3">
 *                <label for="table_control_3"></label>
 *            </label>
 *        </td>
 *        <td>3</td>
 *        <td>Worf</td>
 *        <td>PITA</td>
 *    </tr>
 * </table>
 *
 */

.#{$class-prefix}-table_control {
    margin: 0;
    padding: 0 0 0 getGrid(3px) !important;
    max-width: getGrid(11px);
    width: getGrid(10px);
}
