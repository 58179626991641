/**
 *
 * Paperbase css framework
 * @author Lars Kroll
 *
 */

/**
 * @module Typography variables
 */

// ini

$class-framework: paperbase !default;
$class-prefix: pb !default;
$color-primary-base: $md-list-blue-grey !default;
$color-secondary-base: $md-list-deep-orange !default;

$typography-font: 'Roboto' !default;
$typography-font-fallback: 'Arial, Helvetica, sans-serif' !default;

$grid-baseunit: 4 !default;
$grid-baseblockunit: 56 !default;

$decoration-radius: 4px !default;
$decoration-radius-small: 2px !default;
$decoration-radius-medium: 6px !default;
$decoration-radius-large: 16px !default;

$animation-ease: cubic-bezier(0.19, 1, 0.22, 1) !default;
